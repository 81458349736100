<template>
  <v-speed-dial
    v-model="isCounting"
    direction="right"
    transition="scale-transition"
  >
    <template #activator>
      <v-btn
        v-model="isCounting"
        fab
        text
        small
        :ripple="false"
        class="d-flex align-center"
      >
        <v-icon v-if="isCounting">
          {{ icons.mdiCheck }}
        </v-icon>

        <span
          v-else
          class="text-base d-flex align-center"
        >
          <slot name="prepend-number" />

          <transition name="fade">
            <span
              :key="`number-counter-activator-${number}-${Math.random()}`"
              style="animation-duration: 0.1s"
            >
              {{ number }}
            </span>
          </transition>

          <slot name="append-number" />
        </span>
      </v-btn>
    </template>

    <v-btn
      fab
      x-small
      :ripple="false"
      color="error"
      @click.stop="change(number-1)"
    >
      <v-icon>{{ icons.mdiMinus }}</v-icon>
    </v-btn>

    <v-btn
      fab
      text
      :ripple="false"
      class="text-base"
    >
      <transition name="fade">
        <span
          :key="`number-counter-btn-${number}-${Math.random()}`"
          style="animation-duration: 0.1s"
        >
          {{ number }}
        </span>
      </transition>
    </v-btn>

    <v-btn
      fab
      x-small
      :ripple="false"
      color="primary"
      @click.stop="change(number+1)"
    >
      <v-icon>{{ icons.mdiPlus }}</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import {
  mdiCheck,
  mdiMinus,
  mdiPlus,
} from '@mdi/js'

export default {
  props: {
    number: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    openByDefault: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const isCounting = ref(props.openByDefault)

    const change = newNum => {
      if (newNum < props.min) return

      emit('change', newNum)
    }

    return {
      isCounting,
      change,
      icons: {
        mdiCheck,
        mdiMinus,
        mdiPlus,
      },
    }
  },
}
</script>
