<!-- eslint-disable vue/singleline-html-element-content-newline -->
<template>
  <div
    v-if="isVisible"
    :style="{ paddingRight: $vuetify.breakpoint.xsOnly ? 0 : '400px' }"
  >
    <!-- NOTE: :is-editingに&& isDrawerOpenも渡しているのはモバイル対応（渡さないと目玉アイコンが点灯したままになる） -->
    <vending-histories
      :customer-count="customerCount"
      :table-id="tableId"
      :start-at="startAt"
      :subtotal="isEditingVendingHistories ? '?' : subtotal"
      :discount-fee="discountFee"
      :service-fee="isEditingVendingHistories ? '?' : serviceFee"
      :total="isEditingVendingHistories ? '?' : total - discountFee"
      :paid-in-advance-amount="paidInAdvanceAmount"
      :is-editing="isEditingVendingHistories && isDrawerOpen"
      :table-name="tableName"
      :style="{ paddingTop: `${headerHeight}px`}"
      class="h-full"
      @eye:opened="editVendingHistories($event)"
      @eye:closed="cancelEditingVendingHistories"
      @order="orderFromVendingHistory($event)"
    >
      <!-- NOTE 注文履歴追加 start -->
      <!-- (伝票詳細の注文追加でも、再注文でも使ってる) -->
      <template #below-items>
        <v-overlay
          :value="isEditingVendingHistories"
          @click.native="cancelEditingVendingHistories"
        />

        <v-card class="d-flex align-center justify-center py-10">
          <v-dialog
            v-model="isOrdering"
            width="400"
            scrollable
          >
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                icon
                large
                :ripple="false"
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon large>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>

            <v-card
              class="py-4"
              :height="'100%'"
            >
              <v-card-text>
                <!-- eslint-disable vue/v-on-event-hyphenation -->
                <order-card
                  v-for="(vendingHistoryTemp, vendingHistoryTempIdx) in vendingHistoriesTemp"
                  :key="`vendingHistoriesTemp[${vendingHistoryTempIdx}]`"
                  v-model="vendingHistoryTemp.value"
                  :vendibles="vendibles"
                  :users="users"
                  :size="vendingHistoryTemp.size"
                  class="mb-4"
                  @update:vendibleId="vendingHistoryTemp.value.vendibleId = $event"
                  @update:vendibleType="vendingHistoryTemp.value.vendibleType = $event"
                  @update:isNonExistVendible="vendingHistoryTemp.value.isNonExistVendible = $event"
                  @update:vendibleName="vendingHistoryTemp.value.vendibleName = $event"
                  @update:sellingPrice="vendingHistoryTemp.value.sellingPrice = $event"
                  @update:taxCharge="vendingHistoryTemp.value.taxCharge = $event"
                  @update:vendingContributionsAttributes="vendingHistoryTemp.value.vendingContributionsAttributes = $event"
                  @update:isArbitraryVendingContribution="vendingHistoryTemp.isArbitraryVendingContribution = $event"
                  @update:vendibleRecipientsAttributes="vendingHistoryTemp.value.vendibleRecipientsAttributes = $event"
                  @update:size="vendingHistoryTemp.size = $event"
                  @update:isPaidInAdvance="vendingHistoryTemp.value.isPaidInAdvance = $event"
                  @remove="removeVendingHistoryTempByIndex(vendingHistoryTempIdx)"
                />

                <v-card
                  elevation="0"
                  class="d-flex flex-column align-center justify-center"
                >
                  <v-text-field
                    :value="`¥${currentTotal.toLocaleString()}`"
                    readonly
                    hide-details
                    disabled
                    class="right-input font-weight-bold px-2 pb-4 w-full"
                  >
                    <template #prepend-inner>
                      <span class="text-sm">
                        現在の合計支払額
                      </span>
                    </template>
                  </v-text-field>

                  <total-display
                    :subtotal="estimatedSubtotal"
                    :tax="estimatedServiceFee"
                    :discount-fee="discountFee"
                    :processing-fee="processingFee"
                    :total="estimatedTotal"
                    :paid-in-advance-amount="estimatedPaidInAdvanceAmount"
                    :title="'注文後'"
                    :subtotal-msg="'小計（合計）'"
                    :tax-msg="'税サ等（合計）'"
                    :total-msg="'合計支払額'"
                    class="mb-4 px-2"
                  />

                  <div class="w-full d-flex justify-space-around align-center px-5">
                    <confirm-required-button
                      :is-confirmed="isShow ? isConfirmed.updateVendingHistories : true"
                      :is-valid="isValidToSubmit"
                      :is-submitting="isSubmitting"
                      :btn-message="'注文確定'"
                      :cancelable="true"
                      :already-confirmed="!isShow"
                      @confirmed="isConfirmed.updateVendingHistories = $event"
                      @cancel="isOrdering = false"
                      @clicked="isShow ? updateVendingHistories() : createVendingHistories()"
                    />
                  </div>
                </v-card>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-card>
      </template>
      <!-- NOTE 注文履歴追加 end -->
    </vending-histories>

    <v-navigation-drawer
      v-show="isDrawerOpen"
      app
      right
      stateless
      temporary
      permanent
      hide-overlay
      :width="drawerWidth"
      style="padding-left: 1px"
    >
      <!-- NOTE: padding-left: 1pxはv-tabsで左のoutlineが隠れないようにするため -->
      <v-card elevation="0">
        <v-card-text
          class="pb-16 mb-8"
          :style="{ paddingTop: `${headerHeight + 16}px`}"
        >
          <div v-if="!isEditingVendingHistories">
            <div
              v-if="isShow"
              class="text-sm text--secondary my-4 px-2 d-flex align-center"
            >
              発行日時:
              <v-spacer />
              {{ dateTime(receipt.attributes.createdAt) }}
            </div>

            <!-- NOTE: お会計 start -->
            <v-text-field
              :value="`¥${currentTotal.toLocaleString()}`"
              readonly
              hide-details
              class="right-input text-xl font-weight-semibold px-2"
            >
              <template #prepend-inner>
                <span class="text-base text-no-wrap">
                  合計支払額
                </span>
              </template>
            </v-text-field>

            <total-display
              :subtotal="subtotal"
              :tax="serviceFee"
              :discount-fee="discountFee"
              :processing-fee="processingFee"
              :total="currentTotal"
              class="mb-12 pt-1 px-2"
            >
              <template #below-title>
                <v-switch
                  v-model="triggerProcessingFeeCalc"
                  messages="会計手数料"
                  dense
                />
              </template>
            </total-display>

            <div
              v-for="(payment, paymentIdx) in paymentsAttributes"
              :key="`paymentsAttributes[${paymentIdx}]`"
              :class="payment._destroy ? '_destroy-color' : ''"
              class="mb-5"
            >
              <v-text-field
                v-model.number="payment.amount"
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                :label="paymentsAttributes.length > 1 ? `お預かり${paymentIdx+1}` : 'お預かり'"
                outlined
                hide-details
                prefix="¥"
                :append-icon="icons.mdiPlus"
                :prepend-icon="paymentsAttributes.length > 1 ? icons.mdiCloseCircleOutline : ''"
                @click:append="addPaymentAttributes"
                @click:prepend="removePaymentAttributes(paymentIdx)"
              />

              <div class="d-flex justify-space-between align-center">
                <!-- NOTE: お釣りからお支払い金額を自動入力するボタン -->
                <v-btn
                  :ripple="false"
                  color="primary"
                  max-width="48"
                  min-width="48"
                  :disabled="change >= 0 || payment._destroy"
                  :class="paymentsAttributes.length > 1 ? 'ml-7' : ''"
                  @click="payment.amount = Number(payment.amount) + Number(change * -1)"
                >
                  MAX
                </v-btn>

                <v-btn-toggle
                  v-model="payment.paymentMethod"
                  mandatory
                  color="primary"
                  dense
                  group
                >
                  <v-btn
                    v-for="method in paymentMethods"
                    :key="method.value"
                    :value="method.value"
                    :disabled="payment._destroy"
                    :ripple="false"
                    class="vertical-btn-text"
                  >
                    <v-icon small>{{ method.icon }}</v-icon>
                    <span class="text-xs">{{ method.name }}</span>
                  </v-btn>
                </v-btn-toggle>
              </div>
            </div>

            <v-expansion-panels
              v-model="balanceReceivableExpansionOpener"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="py-0 px-2">
                  <div class="d-flex align-center">
                    <v-spacer />
                    <v-icon left>{{ icons.mdiCalendarAlert }}</v-icon>
                    <span>売掛</span>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  v-for="(balanceReceivable, balanceReceivableIdx) in balanceReceivablesAttributes"
                  :key="`balanceReceivablesAttributes[${balanceReceivableIdx}]`"
                  :class="balanceReceivable._destroy ? '_destroy-color' : ''"
                >
                  <v-text-field
                    v-model.number="balanceReceivable.amount"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    outlined
                    hide-details
                    prefix="¥"
                    :append-icon="icons.mdiPlus"
                    :prepend-icon="(balanceReceivablesAttributes.length > 1 || isShow) ? icons.mdiCloseCircleOutline : ''"
                    @click:append="addBalanceReceivablesAttributes"
                    @click:prepend="removeBalanceReceivablesAttributes(balanceReceivableIdx)"
                  />
                  <v-textarea
                    v-model="balanceReceivable.note"
                    placeholder="ノート"
                    rows="3"
                    :prepend-icon="icons.mdiCommentQuoteOutline"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-text-field
              :value="`¥${totalPaymentAmount.toLocaleString()}`"
              readonly
              hide-details
              class="right-input text-xl font-weight-semibold px-2 mb-8"
            >
              <template #prepend-inner>
                <span class="text-base text-no-wrap">
                  お預かり合計
                </span>
              </template>
            </v-text-field>

            <v-text-field
              v-model.number="discountFee"
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              label="値引き"
              :min="0"
              :rules="[rules.discount]"
              outlined
              hide-details
              prefix="¥"
            />

            <v-text-field
              :value="`¥${change.toLocaleString()}`"
              readonly
              hide-details
              :error="!isValidChange"
              class="mt-8 mb-6 right-input text-xl font-weight-semibold px-2"
            >
              <template #prepend-inner>
                <span class="text-base">
                  お釣り
                </span>
              </template>
            </v-text-field>

            <v-subheader>
              <v-icon left>
                {{ icons.mdiPrinterPos }}
              </v-icon>
              レシート
            </v-subheader>
            <v-divider />

            <div v-if="!isShow">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-checkbox
                      v-model="withReceiptPrint"
                      :disabled="!isReceiptPrinterConnected"
                      :ripple="false"
                      label="レシートを印刷"
                      dense
                      hide-details
                      class="mt-4 ms-4"
                    />

                    <v-checkbox
                      v-model="withJapAssReceiptPrint"
                      :disabled="!isReceiptPrinterConnected"
                      :ripple="false"
                      label="領収書を印刷"
                      dense
                      hide-details
                      class="mt-4 ms-4"
                    />

                    <v-checkbox
                      v-model="withDebtConfirmationPrint"
                      :disabled="!balanceReceivableAmount || !(balanceReceivableAmount > 0)"
                      :ripple="false"
                      label="債務確認書を印刷"
                      dense
                      hide-details
                      class="mt-4 ms-4"
                    />
                  </span>
                </template>

                <span v-if="!isReceiptPrinterConnected">
                  機能を有効にするためはプリンタとの接続が必要です
                </span>
              </v-tooltip>

              <v-checkbox
                v-model="withQRcode"
                label="電子レシートを共有"
                :ripple="false"
                dense
                hide-details
                class="mt-4 ms-4"
              />

              <v-checkbox
                v-model="withReceiptDetails"
                label="明細を印字"
                :ripple="false"
                dense
                hide-details
                class="mt-6 ms-4"
              />

              <Preview
                :slip-svg="settlementSlipSvg"
                class="mt-4"
              />
            </div>

            <div
              v-else
              class="mt-4"
            >
              <print-btn
                v-if="isShow"
                subject="レシート"
                :print="printSettlementSlip"
                :block="true"
              >
                <template #append>
                  <v-checkbox
                    v-model="withReceiptDetails"
                    label="明細を印字"
                    dense
                    hide-details
                    :ripple="false"
                    class="mt-4 ms-4"
                  />

                  <Preview
                    :slip-svg="settlementSlipSvg"
                    class="mt-1"
                  />

                  <div class="mt-1">
                    <QrCode
                      v-if="receipt.attributes.receiptImages && receipt.attributes.receiptImages.length > 0"
                      :urls="receipt.attributes.receiptImages"
                      class="mt-1"
                    />
                    <v-btn
                      v-else
                      text
                      color="primary"
                      :ripple="false"
                      @click="submitReceipt"
                    >
                      <v-icon left>
                        {{ icons.mdiQrcode }}
                      </v-icon>
                      電子レシートを発行
                    </v-btn>
                  </div>
                </template>
              </print-btn>

              <div
                v-if="isShow"
              >
                <div class="mt-8">
                  <print-btn
                    subject="領収書"
                    :print="printReceiptSlip"
                    :block="true"
                    class="mt-8"
                  />
                </div>
                <div class="mt-8">
                  <print-btn
                    v-if="balanceReceivableAmount && balanceReceivableAmount > 0"
                    subject="債務確認書"
                    :print="printDebtConfirmationSlip"
                    :block="true"
                    class="mt-8"
                  />
                </div>
              </div>

              <v-divider class="mt-10" />
              <v-dialog
                v-model="isDeleting"
                width="400"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    color="error"
                    block
                    class="mt-4"
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left>
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>削除
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    削除の確認
                  </v-card-title>

                  <v-card-text>
                    削除を実行してもよろしいですか（卓を含めたこの伝票に関連する全てのデータも削除します。）
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      :loading="isSubmitting"
                      :disabled="isSubmitting"
                      :ripple="false"
                      @click="deleteReceipt"
                    >
                      同意の上で削除
                    </v-btn>
                    <v-btn
                      text
                      :ripple="false"
                      @click="isDeleting = false"
                    >
                      キャンセル
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <v-app-bar
              bottom
              prominent
              outlined
              fixed
              height="76"
            >
              <div class="d-flex flex-column align-center w-full h-full px-5">
                <v-spacer />
                <confirm-required-button
                  :is-confirmed="isConfirmed.submitReceipt"
                  :is-valid="isValidToCheckout"
                  :is-submitting="isSubmittingReceipt"
                  :btn-message="isShow ? '更新' : '会計'"
                  @confirmed="isConfirmed.submitReceipt = $event"
                  @clicked="submitReceipt"
                />
                <v-spacer />
              </div>
            </v-app-bar>
          </div>
          <!-- NOTE: お会計 end -->

          <!-- NOTE: 注文履歴編集 start -->
          <div v-else>
            <div class="d-flex">
              <v-spacer />
              <v-switch
                v-model="isEditingVendingHistories"
                label="編集中"
                hide-details
                loading="primary"
                @click="cancelEditingVendingHistories"
              >
              </v-switch>
            </div>

            <v-card
              v-if="vendingHistoriesTemp.length > 0"
              elevation="0"
              class="mb-10"
            >
              <!-- eslint-disable vue/v-on-event-hyphenation -->
              <order-card
                v-for="(vendingHistoryTemp, vendingHistoryTempIdx) in vendingHistoriesTemp"
                :key="`vendingHistoriesTemp[${vendingHistoryTempIdx}]`"
                v-model="vendingHistoryTemp.value"
                :vendibles="vendibles"
                :users="users"
                :size="vendingHistoryTemp.size"
                :sizable="false"
                :ordered-at="vendingHistoryTemp.orderedAt"
                class="mt-3 mb-8"
                @update:vendibleId="vendingHistoryTemp.value.vendibleId = $event"
                @update:vendibleType="vendingHistoryTemp.value.vendibleType = $event"
                @update:isNonExistVendible="vendingHistoryTemp.value.isNonExistVendible = $event"
                @update:vendibleName="vendingHistoryTemp.value.vendibleName = $event"
                @update:sellingPrice="vendingHistoryTemp.value.sellingPrice = $event"
                @update:taxCharge="vendingHistoryTemp.value.taxCharge = $event"
                @update:vendingContributionsAttributes="vendingHistoryTemp.value.vendingContributionsAttributes = $event"
                @update:_destroy="vendingHistoryTemp.value._destroy = $event"
                @update:isArbitraryVendingContribution="vendingHistoryTemp.isArbitraryVendingContribution = $event"
                @update:vendibleRecipientsAttributes="vendingHistoryTemp.value.vendibleRecipientsAttributes = $event"
                @update:size="vendingHistoryTemp.size = $event"
                @update:isPaidInAdvance="vendingHistoryTemp.value.isPaidInAdvance = $event"
                @remove="removeVendingHistoryTempByIndex(vendingHistoryTempIdx)"
              />
            </v-card>

            <v-text-field
              :value="`¥${currentTotal.toLocaleString()}`"
              readonly
              hide-details
              disabled
              class="right-input font-weight-bold px-2 pb-4"
            >
              <template #prepend-inner>
                <span class="text-sm">
                  現在の合計支払額
                </span>
              </template>
            </v-text-field>
            <total-display
              :subtotal="estimatedSubtotal"
              :tax="estimatedServiceFee"
              :discount-fee="discountFee"
              :processing-fee="processingFee"
              :total="estimatedTotal"
              :paid-in-advance-amount="estimatedPaidInAdvanceAmount"
              :title="'更新後'"
              :subtotal-msg="'小計（合計）'"
              :tax-msg="'税サ等（合計）'"
              :total-msg="'合計支払額'"
              class="mb-4 px-2"
            />

            <v-app-bar
              bottom
              outlined
              fixed
              height="76"
            >
              <div class="d-flex flex-column align-center w-full h-full px-5">
                <v-spacer />
                <confirm-required-button
                  :is-confirmed="isConfirmed.updateVendingHistories"
                  :is-valid="isValidToSubmit"
                  :is-submitting="isSubmitting"
                  :btn-message="'更新'"
                  :cancelable="true"
                  @confirmed="isConfirmed.updateVendingHistories = $event"
                  @cancel="cancelEditingVendingHistories"
                  @clicked="updateVendingHistories"
                />
                <v-spacer />
              </div>
            </v-app-bar>
          </div>
        <!-- NOTE: 注文履歴編集 end -->
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  inject,
  provide,
  watch,
  getCurrentInstance,
} from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import {
  sumBy,
  includes,
  each,
  map,
  cloneDeep,
  reject,
  isEqual,
  update,
  filter,
  chain,
} from 'lodash'
import {
  mdiPrinterPos,
  mdiCurrencyJpy,
  mdiCreditCard,
  mdiPlus,
  mdiCloseCircleOutline,
  mdiCommentQuoteOutline,
  mdiTrashCanOutline,
  mdiSubdirectoryArrowRight,
  mdiQrcode,
  mdiCalendarAlert,
} from '@mdi/js'
import { getVuetify, useRouter } from '@core/utils'
import { useReceiptSlipPrint, useSettlementSlipPrint, useDebtConfirmationSlipPrint } from '@yonarezi/yonarezi3.slip-print'
import { removePortFromIpAddress } from '@/utils/removePortFromIpAddress'
import useVendingHistory from '@/views/composable/useVendingHistory'
import useDateFormat from '@/views/composable/useDateFormat'
import useCurrentData from '@/views/composable/useCurrentData'
import TableApi from '@/api/waiter/Table'
import VendingHistoryApi from '@/api/waiter/VendingHistory'
import ReceiptApi from '@/api/waiter/Receipt'
import OrderCard from '@/views/components/table/OrderCard/OrderCard.vue'
import ConfirmRequiredButton from '@/views/components/util/ConfirmRequiredButton.vue'
import TotalDisplay from '@/views/components/util/TotalDisplay.vue'
import PrintBtn from '@/views/components/util/PrintBtn.vue'
import Preview from '@/views/components/printer/Preview.vue'
import QrCode from '@/views/components/printer/QrCode.vue'
import VendingHistories from './VendingHistories.vue'

export default {
  components: {
    VendingHistories,
    OrderCard,
    ConfirmRequiredButton,
    TotalDisplay,
    PrintBtn,
    Preview,
    QrCode,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    isCheckDrawerOpen: {
      type: Boolean,
      default: true,
    },
    headerHeight: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      default: 46,
    },
    drawerWidth: {
      type: [Number, String],
      default: 400,
    },
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tableName: {
      type: String,
      required: true,
      default: '',
    },
    customerCount: {
      type: [Number, String],
      required: true,
    },
    startAt: {
      type: String,
      required: true,
    },
    receipt: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const $vuetify = getVuetify()
    const { router } = useRouter()

    const isDrawerOpen = useVModel(props, 'isCheckDrawerOpen')

    const users = inject('users', [])
    const vendibles = inject('vendibles', [])
    const isReceiptPrinterConnected = inject('isReceiptPrinterConnected', false)
    const printer = inject('printer', null)
    const referringHistories = inject('referringHistories', [])

    const isConfirmed = reactive({
      submitReceipt: false,
      updateVendingHistories: false,
    })
    const isEditingVendingHistories = ref(false)
    const isSubmitting = ref(false)
    const isSubmittingReceipt = ref(false)
    const isOrdering = ref(false)
    const isDeleting = ref(false)
    const total = ref(0) // 丸め処理済みの大計
    const discountFee = ref(0) // 値引金額
    const processingFee = ref(0)
    const paymentsAttributes = ref([])
    const balanceReceivablesAttributes = ref([])
    const balanceReceivableExpansionOpener = ref()
    const estimatedTotal = ref(0)
    const triggerProcessingFeeCalc = ref()
    const withReceiptPrint = ref(true)
    const withReceiptDetails = ref(true)
    const withQRcode = ref(!isReceiptPrinterConnected.value)
    const withJapAssReceiptPrint = ref(false)
    const withDebtConfirmationPrint = ref(false)

    const rules = {
      discount: value => value !== '',
    }

    const paymentMethods = [
      {
        value: 'cash',
        name: '現金',
        icon: mdiCurrencyJpy,
      },
      {
        value: 'card',
        name: 'カード',
        icon: mdiCreditCard,
      },
    ]

    // NOTE VendingHistoriesのコンポーネントでvending_historiesをinjectしてる
    // Hall配下ではそれがTableShowDrawerからprovideされてる
    // このreceiptVendingHistoriesは伝票の編集で使う（receiptがvendingHistoriesを持ってる)
    // このreceiptVendingHistoriesに代入後,provideすることでVendingHistoriesのコンポーネントに渡す
    //  + useVendingHistoryのvendingHistoriesにもinjectされる
    const receiptVendingHistories = ref([])
    // NOTE: receipt自体がvendingHistories持ってたらそっち使ってもらえるよう
    if (props.receipt?.attributes?.vendingHistories?.data?.length > 0) {
      receiptVendingHistories.value = [...props.receipt.attributes.vendingHistories.data]
      // TODO: 既存のホールの絡みでvendingHistoriesをinjectする感じになっててキモイ。
      // ホールでは現在進行形の卓が表示されているのでreceiptがない一方、データ>伝票画面での編集時でもこのcompが使われていてこじれてる
      provide('vendingHistories', receiptVendingHistories)
      // NOTE: useVendingHistoryのvendingHistoriesにもinjectされるためには、それより上にprovideの記述をしなければならい
    }
    const {
      vendingHistories,
      vendingHistoriesTemp,
      addVendingHistoryTemp,
      removeVendingHistoryTempByIndex,
      resetVendingHistoriesTemp,
      isValidToSubmit,
      vendingHistoriesToSubmit,
      setVendingContributionsAttributes,
    } = useVendingHistory()

    const { currentClub } = useCurrentData()
    const { dateTime, hhmm } = useDateFormat()

    // NOTE 保存済みの販売履歴から編集対象の販売履歴を除いたもの（編集モードでなければ保存済みのものだけを返す）
    const vendingHistoriesWithoutUpdateTargets = computed(() => {
      const updateTargetIds = map(vendingHistoriesToSubmit.value, obj => Number(obj.id))

      return reject(vendingHistories.value, obj => {
        return includes(updateTargetIds, Number(obj.id))
      })
    })

    // NOTE: 前金フラグのついた販売履歴
    const vendingHistoriesWithoutUpdateTargetsPaidInAdvance = computed(() => {
      return chain(vendingHistoriesWithoutUpdateTargets.value)
        .filter('attributes.isPaidInAdvance')
        .value()
    })

    // NOTE: 前金フラグのついた販売履歴(編集対象)
    const vendingHistoriesTempPaidInAdvance = computed(() => {
      return chain(vendingHistoriesTemp.value)
        .filter('value.isPaidInAdvance')
        .value()
    })

    // NOTE: 小計
    const subtotal = computed(() => {
      return sumBy(vendingHistoriesWithoutUpdateTargets.value, obj => +obj.attributes.sellingPrice)
    })

    // NOTE: 小計(前金フラグのついた支払い済)
    const subtotalPaidInAdvance = computed(() => {
      return sumBy(vendingHistoriesWithoutUpdateTargetsPaidInAdvance.value, obj => +obj.attributes.sellingPrice)
    })

    // NOTE: 小計(更新後)(概算用)
    const estimatedSubtotal = computed(() => {
      const subtotalForUpdateTargets = sumBy(vendingHistoriesTemp.value, obj => {
        // eslint-disable-next-line no-underscore-dangle
        if (obj.value._destroy) return 0

        return +obj.value.sellingPrice * obj.size
      })

      // NOTE: 編集対象の小計の合計と,それを除いた販売履歴の小計の合計を合算
      return subtotalForUpdateTargets + subtotal.value
    })

    // NOTE: 小計(更新後)(概算用)(前金フラグがついて支払い済みになる)
    const estimatedSubtotalPaidInAdvance = computed(() => {
      const subtotalPaidInAdvanceForUpdateTargets = sumBy(vendingHistoriesTempPaidInAdvance.value, obj => {
        // eslint-disable-next-line no-underscore-dangle
        if (obj.value._destroy) return 0

        return +obj.value.sellingPrice * obj.size
      })

      // NOTE: 編集対象の小計の合計と,それを除いた販売履歴の小計の合計を合算
      return subtotalPaidInAdvanceForUpdateTargets + subtotalPaidInAdvance.value
    })

    // NOTE: 税サ等
    const serviceFee = computed(() => {
      if (total.value <= 0) return 0

      return sumBy(vendingHistoriesWithoutUpdateTargets.value, obj => {
        // NOTE: tax込み/カットは除外
        if (includes(['cut', 'included'], obj.attributes.taxCharge)) return 0

        return +obj.attributes.taxPrice
      })
    })

    // NOTE: 税サ等(前金フラグのついた支払い済)
    const serviceFeePaidInAdvance = computed(() => {
      if (total.value <= 0) return 0

      return sumBy(vendingHistoriesWithoutUpdateTargetsPaidInAdvance.value, obj => {
        // NOTE: tax込み/カットは除外
        if (includes(['cut', 'included'], obj.attributes.taxCharge)) return 0

        return +obj.attributes.taxPrice
      })
    })

    // NOTE: 税サ等(更新後)(概算用)
    const estimatedServiceFee = computed(() => {
      const serviceFeeForUpdateTargets = sumBy(vendingHistoriesTemp.value, obj => {
        // eslint-disable-next-line no-underscore-dangle
        if (obj.value._destroy) return 0

        return +obj.estimatedTaxPrice * obj.size
      })

      return serviceFeeForUpdateTargets + serviceFee.value
    })

    // NOTE: 税サ等(更新後)(概算用)(前金フラグがついて支払い済みになる)
    const estimatedServiceFeePaidInAdvance = computed(() => {
      const serviceFeeForUpdateTargetsPaidInAdvance = sumBy(vendingHistoriesTempPaidInAdvance.value, obj => {
        // eslint-disable-next-line no-underscore-dangle
        if (obj.value._destroy) return 0

        return +obj.estimatedTaxPrice * obj.size
      })

      return serviceFeeForUpdateTargetsPaidInAdvance + serviceFeePaidInAdvance.value
    })

    // NOTE: 前金
    const paidInAdvanceAmount = computed(() => {
      return subtotalPaidInAdvance.value + serviceFeePaidInAdvance.value
    })

    // NOTE: 前金(更新後)(概算用)
    const estimatedPaidInAdvanceAmount = computed(() => {
      return estimatedSubtotalPaidInAdvance.value + estimatedServiceFeePaidInAdvance.value
    })

    // NOTE: total(丸め処理済みの最終的な合計金額) + 会計手数料 - 値引き金額
    const currentTotal = computed(() => {
      return Number(total.value) + Number(processingFee.value) - Number(discountFee.value)
    })

    // NOTE: 合計お預かり金額
    const totalPaymentAmount = computed(() => {
      const totalPaymentAttributesAmount = sumBy(paymentsAttributes.value, obj => {
        // eslint-disable-next-line no-underscore-dangle
        return obj._destroy ? 0 : Number(obj.amount)
      })
      const totalBalanceReceivablesAttributes = sumBy(balanceReceivablesAttributes.value, obj => {
        // eslint-disable-next-line no-underscore-dangle
        return obj._destroy ? 0 : Number(obj.amount)
      })

      return totalPaymentAttributesAmount + totalBalanceReceivablesAttributes
    })

    // NOTE: お釣り
    const change = computed(() => {
      const result = (currentTotal.value - totalPaymentAmount.value) * -1

      // -0を出さない
      return 1 / result === -Infinity ? 0 : result
    })

    const isValidDiscountFee = () => discountFee.value !== ''
    const isValidChange = () => change.value >= 0

    // NOTE: お釣りがマイナス（預かり金が足りていない）じゃないか？
    const isValidToCheckout = computed(() => {
      return isValidChange() && isValidDiscountFee()
    })

    const receiptAttributes = computed(() => {
      return {
        total: total.value,
        discountFee: discountFee.value,
        processingFee: processingFee.value,
        serviceFee: serviceFee.value,
        subtotal: subtotal.value,
        paymentsAttributes: filter(paymentsAttributes.value, o => o.amount > 0),
        balanceReceivablesAttributes: filter(balanceReceivablesAttributes.value, o => o.amount > 0),
      }
    })

    const isShow = computed(() => {
      return props.receipt?.id && props.receipt?.attributes
    })

    const paymentAmounts = computed(() => {
      let cash = 0
      let card = 0
      each(paymentsAttributes.value, attr => {
        const { paymentMethod, amount, _destroy: isToDelete } = attr
        if (!isToDelete) {
          if (paymentMethod === 'cash') cash += amount
          if (paymentMethod === 'card') card += amount
        }
      })

      return {
        cash,
        card,
      }
    })

    const addPaymentAttributes = ({
      id = null,
      amount = 0,
      paymentMethod = 'cash',
      _destroy = false,
    }) => {
      paymentsAttributes.value.push({
        id,
        amount,
        paymentMethod,
        _destroy,
      })
    }

    const removePaymentAttributes = paymentIdx => {
      const removeTarget = paymentsAttributes.value.splice(paymentIdx, 1)[0]

      // NOTE: 保存済みのものを削除の場合_destroyをtrueにして元いた場所に返す
      if (removeTarget.id) {
        // eslint-disable-next-line no-underscore-dangle
        removeTarget._destroy = !removeTarget._destroy
        paymentsAttributes.value.splice(paymentIdx, 0, removeTarget)
      }

      return removeTarget
    }

    const addBalanceReceivablesAttributes = ({
      id = null,
      amount = 0,
      note = '',
      _destroy = false,
    }) => {
      balanceReceivablesAttributes.value.push({
        id,
        amount,
        note,
        _destroy,
      })
    }

    const removeBalanceReceivablesAttributes = balanceReceivableIdx => {
      const removeTarget = balanceReceivablesAttributes.value.splice(balanceReceivableIdx, 1)[0]

      // NOTE: 保存済みのものを削除の場合_destroyをtrueにして元いた場所に返す
      if (removeTarget.id) {
        // eslint-disable-next-line no-underscore-dangle
        removeTarget._destroy = !removeTarget._destroy
        balanceReceivablesAttributes.value.splice(balanceReceivableIdx, 0, removeTarget)
      }

      return removeTarget
    }

    const addVendingHistoryTempFromData = ({ isNew = false, actualData }) => {
      each(actualData, vendingHistory => {
        const {
          vendibleId,
          vendibleType,
          taxCharge,
          sellingPrice,
          isNonExistVendible,
          createdAt,
          isPaidInAdvance,
        } = vendingHistory.attributes

        // NOTE: vendibleNameはisNonExistVendible用の属性、vendibleId持つ場合は不必要
        const vendibleName = vendibleId ? undefined : vendingHistory.attributes.vendibleName

        const vendingContributionsAttributes = map(vendingHistory.attributes.vendingContributions.data, data => {
          const { userId, vendingHistoryId, ratio } = data.attributes

          return {
            id: isNew ? null : data.id,
            userId,
            vendingHistoryId,
            ratio,
            _destroy: null,
          }
        })

        const vendibleRecipientsAttributes = map(vendingHistory.attributes.vendibleRecipients.data, data => {
          const { userId, vendingHistoryId, ratio } = data.attributes

          return {
            id: isNew ? null : data.id,
            userId,
            vendingHistoryId,
            ratio,
            _destroy: null,
          }
        })

        addVendingHistoryTemp({
          id: isNew ? null : vendingHistory.id,
          vendibleId,
          vendibleType,
          taxCharge,
          sellingPrice,
          isNonExistVendible,
          vendibleName,
          vendingContributionsAttributes,
          vendibleRecipientsAttributes,
          size: 1,
          isArbitraryVendingContribution: true,
          orderedAt: createdAt,
          isPaidInAdvance,
        })
      })
    }

    const editVendingHistories = actualData => {
      isEditingVendingHistories.value = true
      isDrawerOpen.value = true
      addVendingHistoryTempFromData({ isNew: false, actualData })
    }

    const cancelEditingVendingHistories = () => {
      resetVendingHistoriesTemp()

      if ($vuetify.breakpoint.xsOnly) {
        isDrawerOpen.value = false
      }

      isEditingVendingHistories.value = false
    }

    const updateVendingHistories = async () => {
      isSubmitting.value = true

      if (isValidToSubmit.value && isConfirmed.updateVendingHistories) {
        const res = await VendingHistoryApi.updateVendingHistories({
          tableId: props.tableId,
          vendingHistories: vendingHistoriesToSubmit.value,
        })

        if (res?.data) {
          emit('updated:vending-histories', res.data.vendingHistories.data)
          emit('updated:table', res.data.table.data)

          vm.$toast.success('注文履歴を更新しました')

          if (isShow.value) {
            emit('updated:receipt', res.data.table.data.attributes.receipt.data)
            receiptVendingHistories.value = [...res.data.vendingHistories.data]
            vm.$toast.success('伝票を更新しました')
          }
        }
      }

      isSubmitting.value = false
      isOrdering.value = false
      cancelEditingVendingHistories()
    }

    const createVendingHistories = async () => {
      isSubmitting.value = true

      if (isValidToSubmit.value) {
        const res = await VendingHistoryApi.createVendingHistories({
          tableId: props.tableId,
          vendingHistories: vendingHistoriesToSubmit.value,
        })

        if (res?.status === 200) {
          emit('updated:vending-histories', res.data.vendingHistories.data)
          emit('updated:table', res.data.table.data)
          vm.$toast.success('注文しました')
        }
      }

      isSubmitting.value = false
      isOrdering.value = false
      cancelEditingVendingHistories()
    }

    const calcTotal = async (price = null) => {
      const res = await TableApi.calcTotal({
        tableId: props.tableId,
        price,
      })

      return res?.data
    }

    const calcProcessingFee = async (price = null) => {
      const res = await TableApi.calcProcessingFee({
        tableId: props.tableId,
        price,
      })

      return res?.data
    }

    const calcTax = async price => {
      const res = await TableApi.calcTax({
        tableId: props.tableId,
        price,
      })

      return res?.data
    }

    const resetCheckComp = () => {
      isDrawerOpen.value = true
      isConfirmed.submitReceipt = false
      isConfirmed.updateVendingHistories = false
      isEditingVendingHistories.value = false
      resetVendingHistoriesTemp()

      if (!isShow.value) {
        paymentsAttributes.value = []
        addPaymentAttributes({})

        balanceReceivablesAttributes.value = []
        balanceReceivableExpansionOpener.value = undefined
      }
    }

    const orderFromVendingHistory = actualData => {
      // NOTE: 再注文時に前金フラグは引き継がない
      const dataToPass = { ...actualData[0], ...{ attributes: { ...actualData[0].attributes, isPaidInAdvance: false } } }
      addVendingHistoryTempFromData({ isNew: true, actualData: [dataToPass] })
      isOrdering.value = true
    }

    const deleteReceipt = async () => {
      isSubmitting.value = true
      const res = await ReceiptApi.deleteReceipt(props.receipt.id)

      if (res?.status === 200) emit('deleted')
      isSubmitting.value = false
    }

    // NOTE: YONAREZI 3
    const slipPrintBaseOptions = computed(() => {
      return {
        ipAddress: removePortFromIpAddress(currentClub.value.printIpAddress),
        clubName: currentClub.value.name,
        invoiceNumber: currentClub.value.invoiceNumber,
        address: currentClub.value.address,
        tel: currentClub.value.telephoneNumber,
        customerCount: props.customerCount.toLocaleString(),
        tableCode: props.tableName,
        startAt: hhmm(props.startAt),
        printer: printer.value,
      }
    })
    const receiptSlipOptions = computed(() => {
      return {
        ...slipPrintBaseOptions.value,
        total: currentTotal.value,
      }
    })
    const balanceReceivableAmount = computed(() => {
      let balanceReceivablesAmount = 0
      balanceReceivablesAmount += sumBy(balanceReceivablesAttributes.value, o => Number(o.amount))

      return balanceReceivablesAmount
    })
    const settlementSlipOptions = computed(() => {
      return {
        ...slipPrintBaseOptions.value,
        referringHistories: referringHistories.value || [],
        referralAlias: currentClub.value.referralAlias,
        withOrderDetails: withReceiptDetails.value,
        vendingHistories: vendingHistories.value,
        nominationAlias: currentClub.value.nominationAlias,
        courseAlias: currentClub.value.courseAlias,
        subtotal: subtotal.value,
        // NOTE:
        // serviceFeeって名前だけど内容はtaxの合計金額
        // 会計手数料は印字せずに税サ等に組み込む
        tax: (serviceFee.value + processingFee.value),
        total: currentTotal.value,
        discountAmount: discountFee.value || null,
        cashPaymentAmount: paymentAmounts.value.cash,
        cardPaymentAmount: paymentAmounts.value.card,
        balanceReceivableAmount: balanceReceivableAmount.value,
        totalReceivedAmount: totalPaymentAmount.value,
        change: change.value,
      }
    })
    const debtConfirmationSlipOptions = computed(() => {
      return {
        ...slipPrintBaseOptions.value,
        balanceReceivableAmount: balanceReceivableAmount.value,
      }
    })
    const { send: printReceiptSlip, onReceive: onReceiveReceiptSlipPrint } = useReceiptSlipPrint(receiptSlipOptions)
    const { send: printSettlementSlip, onReceive: onReceiveSettlementSlipPrint, svg: settlementSlipSvg } = useSettlementSlipPrint(settlementSlipOptions)
    const { send: printDebtConfirmationSlip, onReceive: onReceiveDebtConfirmationSlipPrint } = useDebtConfirmationSlipPrint(debtConfirmationSlipOptions)
    onReceiveReceiptSlipPrint.value = response => {
      if (!response.success) {
        vm.$toast.error(response)
      }
    }
    onReceiveSettlementSlipPrint.value = response => {
      if (!response.success) {
        vm.$toast.error(response)
      }
    }
    onReceiveDebtConfirmationSlipPrint.value = response => {
      if (!response.success) {
        vm.$toast.error(response)
      }
    }
    const createSVGDisplayElement = async () => {
      const svgDOM = new DOMParser().parseFromString(settlementSlipSvg.value, 'image/svg+xml').documentElement

      svgDOM.style.width = '100%'
      svgDOM.style.background = 'white'
      svgDOM.style.color = 'black'
      const padding = 20
      const width = svgDOM.width.baseVal.value
      const height = svgDOM.height.baseVal.value
      // viewBoxを設定してSVG全体にパディングを追加
      svgDOM.setAttribute('viewBox', `-${padding} -${padding} ${width + 2 * padding} ${height + 2 * padding}`)
      svgDOM.style.width = `${width + 2 * padding}px`
      svgDOM.style.height = `${height + 2 * padding}px`

      return svgDOM
    }
    const svg2imageData = (svgElement, successCallback, errorCallback) => {
      const canvas = document.createElement('canvas')

      canvas.width = svgElement.width.baseVal.value
      canvas.height = svgElement.height.baseVal.value
      const ctx = canvas.getContext('2d')
      const image = new Image()
      image.onload = () => {
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob(blob => {
          successCallback(blob)
        })
      }
      image.onerror = e => {
        errorCallback(e)
      }
      const svgData = new XMLSerializer().serializeToString(svgElement)
      image.src = `data:image/svg+xml;charset=utf-8;base64,${btoa(unescape(encodeURIComponent(svgData)))}`
    }
    const submitReceipt = async () => {
      isSubmittingReceipt.value = true
      // NOTE: ここで画像データを作成しそれぞれのapiに渡す
      // いずれの場合も新規作成データとする
      const receiptImage = await createSVGDisplayElement()

      // 作成後に削除
      svg2imageData(receiptImage, async blob => {
        try {
          if (isShow.value) {
            const res = await ReceiptApi.updateReceipt({
              id: props.receipt.id,
              receiptAttributes: {
                ...receiptAttributes.value,
                images: blob,
              },
            })

            if (res?.data?.data) {
              emit('updated:receipt', res.data.data)
              vm.$toast.success('伝票を更新しました')
            }
          } else {
            const res = await ReceiptApi.createReceipt({
              tableId: props.tableId,
              receiptAttributes: {
                ...receiptAttributes.value,
                images: blob,
              },
            })

            if (res?.data?.receipt?.data) {
              if (isReceiptPrinterConnected.value) {
                if (withJapAssReceiptPrint.value) await printReceiptSlip()
                if (withReceiptPrint.value) await printSettlementSlip()
                if (withDebtConfirmationPrint.value) await printDebtConfirmationSlip()
              }

              const receipt = res.data.receipt.data
              // NOTE: 電子レシートを共有ONでなかったらreceiptImagesは空に
              if (!withQRcode.value) receipt.attributes.receiptImages = []
              emit('checked', receipt)
            } else {
              vm.$toast.warning('問題が発生したためリダイレクトします')
              router.go(0)
            }
          }
        } finally {
          isSubmittingReceipt.value = false
        }
      }, error => {
        console.log(error)
      })
    }

    watch(() => cloneDeep(vendingHistoriesTemp.value), (_newVal, _prevVal) => {
      // NOTE 編集対象（vendingHistoriesTemp）が変わるたびにisConfirmedはfalseへ
      isConfirmed.updateVendingHistories = false
    })

    // NOTE receiptAttributesが変わるたびにisConfirmedはfalseへ
    watch(() => cloneDeep(receiptAttributes.value), (_newVal, _prevVal) => {
      isConfirmed.submitReceipt = false
    })

    // NOTE vendingHistoriesが変わるたびに合計金額を再計算
    watch(() => cloneDeep(vendingHistories.value), (_newVal, _prevVal) => {
      calcTotal().then(res => {
        total.value = res
      })
    })

    // NOTE: 会計手数料を計算する
    let processingFeeCalcCounter = 0
    watch(
      [
        () => triggerProcessingFeeCalc.value,
        () => paymentAmounts.value.card,
        () => discountFee.value,
      ],
      (
        [newIsTriggered, newCardAmount, newDiscountFee],
        [prevIsTriggered, _prevCardAmount, _prevDiscountFee],
      ) => {
        // 伝票詳細のときは初回のみ計算しない（会計手数料込みのカード金額を再計算してずれてしまうため）
        processingFeeCalcCounter += 1
        if (isShow.value && processingFeeCalcCounter === 1) return

        // 初めてスイッチがオンにされた場面
        const isFirstTimeSwitchOn = newIsTriggered && !prevIsTriggered
        // 初めてスイッチがオフにされた場面
        const isFirstTimeSwitchOff = !newIsTriggered && prevIsTriggered
        // スイッチがオンの状態が維持されている場面
        const isSwitchRemainsOn = newIsTriggered && prevIsTriggered
        if (isSwitchRemainsOn) return // 何もしない

        if (isFirstTimeSwitchOn) {
          const isMultiplePaymentMethod = paymentsAttributes.value.length > 1

          let calcTargetAmount = 0
          if (isMultiplePaymentMethod && newCardAmount > 0) {
            // 決済手段が複数あり且つカード会計金額がある場合はカードのカード会計金額にのみ会計手数料を
            calcTargetAmount = newCardAmount
          } else {
            calcTargetAmount = currentTotal.value
          }

          calcProcessingFee(calcTargetAmount).then(res => {
            processingFee.value = res
          })

          return
        }

        if (isFirstTimeSwitchOff) {
          processingFee.value = 0
        }
      },
    )

    // NOTE: 売掛アコーディオンの開閉状態でbalanceReceivableAttributesを作る/空にする
    watch(() => balanceReceivableExpansionOpener.value, (newVal, _prevVal) => {
      // NOTE: 開いた時は一個つくる（保存済みのものがなければ）
      if (newVal === 0 && balanceReceivablesAttributes.value.length < 1) {
        addBalanceReceivablesAttributes({})
      }

      // NOTE: 閉じる時は全部消す（新規作成のもののみ）
      if (newVal !== 0) {
        each(balanceReceivablesAttributes.value, (obj, index) => {
          if (!obj.id) balanceReceivablesAttributes.value.splice(index, 1)
        })
      }
    })

    // NOTE: 編集時に持ってるpaymentsからpaymentAttributesをつくる
    watch(() => cloneDeep(props?.receipt?.attributes?.payments?.data), (newPayments, _prevPayments) => {
      if (!newPayments) return
      paymentsAttributes.value = []

      if (!!newPayments && newPayments.length === 0) {
        addPaymentAttributes({})
      }

      each(newPayments, payment => {
        const { amount, paymentMethod } = payment.attributes
        addPaymentAttributes({ id: payment.id, amount, paymentMethod })
      })
    }, { immediate: true })

    // NOTE: 編集時に持ってるbalanceReceivablesからbalanceReceivablesAttributesをつくる
    watch(() => cloneDeep(props?.receipt?.attributes?.balanceReceivables?.data), (newBalanceReceivables, _prevBalanceReceivables) => {
      if (!newBalanceReceivables) return

      balanceReceivablesAttributes.value = []

      each(newBalanceReceivables, payment => {
        const { amount, note } = payment.attributes
        addBalanceReceivablesAttributes({ id: payment.id, amount, note })
      })
      if (balanceReceivablesAttributes.value.length > 0) balanceReceivableExpansionOpener.value = 0
    }, { immediate: true })

    // NOTE: 編集の時に値引金額をセット
    watch(() => cloneDeep(props?.receipt?.attributes?.discountFee), (newReceipt, _prevReceipt) => {
      if (!newReceipt) return

      discountFee.value = newReceipt
    }, { immediate: true })

    // NOTE: 更新後の概算を出すためにそれぞれのvendingHistoryの税サを計算
    watch(() => cloneDeep(map(vendingHistoriesTemp.value, obj => [obj.value.sellingPrice, obj.value.taxCharge])), (newVal, prevVal) => {
      if (isEqual(newVal, prevVal)) return

      each(vendingHistoriesTemp.value, vendingHistoryTemp => {
        if (vendingHistoryTemp.value.taxCharge === 'normal') {
          calcTax(vendingHistoryTemp.value.sellingPrice).then(taxPrice => {
            update(vendingHistoryTemp, 'estimatedTaxPrice', () => taxPrice)
          })
        } else {
          update(vendingHistoryTemp, 'estimatedTaxPrice', () => 0)
        }
      })
    }, { immediate: true })

    // NOTE: 概算を出すためにvendingHistory全体の大計を計算
    watch(() => cloneDeep([estimatedSubtotal.value, estimatedServiceFee.value, discountFee.value]), (newVal, prevVal) => {
      if (isEqual(newVal, prevVal)) return

      const rawEstimatedTotal = estimatedSubtotal.value + estimatedServiceFee.value

      calcTotal(rawEstimatedTotal).then(res => {
        estimatedTotal.value = Number(res) + Number(processingFee.value) - Number(discountFee.value)
      })
    }, { immediate: true })

    watch(() => isOrdering.value, (newVal, _prevVal) => {
      if (newVal) {
        if (vendingHistoriesTemp.value.length < 1) addVendingHistoryTemp({})
      } else {
        vendingHistoriesTemp.value = []
      }
    })

    // NOTE: 販売貢献人をvendingHistoryTempに自動セットするwatch
    watch(() => cloneDeep(vendingHistoriesTemp.value), (_newVal, _prevVal) => {
      setVendingContributionsAttributes({
        targetVendingHistoriesTemp: vendingHistoriesTemp.value,
      })
    })

    // NOTE: 前金分をお預かりに自動セット
    watch(() => paidInAdvanceAmount.value, (_newVal, _prevVal) => {
      if (isShow.value) return

      paymentsAttributes.value = []
      addPaymentAttributes({ amount: paidInAdvanceAmount.value, paymentMethod: 'cash' })
    })

    // NOTE: init時
    if (isShow.value) {
      total.value = +props.receipt.attributes.total
      discountFee.value = +props.receipt.attributes.discountFee
      processingFee.value = +props.receipt.attributes.processingFee
      triggerProcessingFeeCalc.value = props.receipt.attributes.processingFee > 0
    } else {
      calcTotal().then(res => { total.value = res })

      addPaymentAttributes({})
    }

    // NOTE: 売掛金の変更
    watch(() => balanceReceivableAmount.value, newBalanceReceivableAmount => {
      if (!newBalanceReceivableAmount || newBalanceReceivableAmount <= 0) {
        withDebtConfirmationPrint.value = false
      }
    })

    emit('init:reset-func', resetCheckComp)
    emit('init:edit-func', editVendingHistories)
    // /init時

    return {
      // data
      users,
      vendibles,
      isDrawerOpen,
      isConfirmed,
      isEditingVendingHistories,
      isSubmitting,
      isSubmittingReceipt,
      isOrdering,
      isDeleting,
      total,
      discountFee,
      processingFee,
      paymentMethods,
      paymentsAttributes,
      balanceReceivablesAttributes,
      balanceReceivableExpansionOpener,
      vendingHistoriesTemp,
      vendingHistories,
      triggerProcessingFeeCalc,
      withReceiptPrint,
      isReceiptPrinterConnected,
      withReceiptDetails,
      withQRcode,

      // computed
      isValidToSubmit,
      isValidToCheckout,
      isShow,
      subtotal,
      serviceFee,
      paidInAdvanceAmount,
      currentTotal,
      totalPaymentAmount,
      change,
      estimatedSubtotal,
      estimatedServiceFee,
      estimatedTotal,
      estimatedPaidInAdvanceAmount,
      receiptAttributes,
      withJapAssReceiptPrint,
      withDebtConfirmationPrint,
      settlementSlipSvg,
      balanceReceivableAmount,

      // methods
      removeVendingHistoryTempByIndex,
      addVendingHistoryTemp,
      editVendingHistories,
      cancelEditingVendingHistories,
      updateVendingHistories,
      createVendingHistories,
      resetVendingHistoriesTemp,
      addPaymentAttributes,
      removePaymentAttributes,
      addBalanceReceivablesAttributes,
      removeBalanceReceivablesAttributes,
      calcTotal,
      calcProcessingFee,
      submitReceipt,
      dateTime,
      orderFromVendingHistory,
      deleteReceipt,
      isValidChange,
      printReceiptSlip,
      printSettlementSlip,
      printDebtConfirmationSlip,

      // input rule
      rules,

      icons: {
        mdiPrinterPos,
        mdiPlus,
        mdiCloseCircleOutline,
        mdiCommentQuoteOutline,
        mdiTrashCanOutline,
        mdiSubdirectoryArrowRight,
        mdiQrcode,
        mdiCalendarAlert,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}
._destroy-color {
  opacity: 0.5;
}
</style>
