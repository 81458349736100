import {
  ref,
  reactive,
  computed,
  inject,
} from '@vue/composition-api'
import {
  map,
  flatMap,
  findIndex,
  uniq,
  chain,
  intersectionWith,
  isEqual,
  each,
  isEmpty,
  filter,
  every,
  mergeWith,
  isArray,
} from 'lodash'
import useValidation from '@/views/composable/useValidation'
import useCurrentData from '@/views/composable/useCurrentData'

export default () => {
  const { currentClub } = useCurrentData()

  const vendingHistoriesTemp = ref([])

  // NOTE: 販売履歴が絡むところが多いので、provide/injectでまとめている。
  // 基本的にtable/配下のコンポーネントでしか使わないので、その卓に紐づく販売履歴だけを受け取ることを想定している
  const vendingHistories = inject('vendingHistories', [])

  // NOTE: Triggerも渡してあげないとsetVendingContributionsAttributesは機能しない
  const vendingContributionTriggers = inject('vendingContributionTriggers', [])
  const referringHistories = inject('referringHistories', [])
  const referringHistoriesTemp = inject('referringHistoriesTemp', [])

  const { isValidId } = useValidation()

  const itemHistoriesTemp = computed(() => {
    return filter(vendingHistoriesTemp.value, historyTemp => historyTemp.value?.vendibleType === 'Item')
  })

  const nominationHistoriesTemp = computed(() => {
    return filter(vendingHistoriesTemp.value, historyTemp => historyTemp.value?.vendibleType === 'Nomination')
  })

  const courseHistoriesTemp = computed(() => {
    return filter(vendingHistoriesTemp.value, historyTemp => historyTemp.value?.vendibleType === 'Course')
  })

  const existVendibleHistoriesTemp = computed(() => {
    return filter(vendingHistoriesTemp.value, historyTemp => !historyTemp.value?.isNonExistVendible)
  })

  const nonExistVendibleHistoriesTemp = computed(() => {
    return filter(vendingHistoriesTemp.value, historyTemp => historyTemp.value?.isNonExistVendible)
  })

  const isValidToSubmit = computed(() => {
    return every(
      // NOTE: 販売時点不存在商品(isNonExistVendible)でなければvendibleIdとvendibleTypeは必要。vendibleNameはいらない。
      existVendibleHistoriesTemp.value,
      vendingHistoryTemp => {
        return isValidId(vendingHistoryTemp.value?.vendibleId)
          && !!vendingHistoryTemp.value?.vendibleType
          && !vendingHistoryTemp.value?.vendibleName
      },
    )
      // NOTE: Nominationであれば受領者が必ず必要
      && every(
        nominationHistoriesTemp.value,
        nominationHistoryTemp => {
          return nominationHistoryTemp.value.vendibleRecipientsAttributes.length > 0
            && every(nominationHistoryTemp.value.vendibleRecipientsAttributes, o => isValidId(o.userId))
        },
      )

      // NOTE: 販売時点不存在商品(isNonExistVendible)であれば、vendibleNameが必要。vendibleIdとvendibleTypeは逆に不必要。
      // sellingPriceは0は許容したいけど、空白、null、undefinedを弾きたい
      && every(
        nonExistVendibleHistoriesTemp.value,
        nonExistVendibleHistoryTemp => {
          return nonExistVendibleHistoryTemp.value?.vendibleName
            && !nonExistVendibleHistoryTemp.value?.vendibleType
            && !nonExistVendibleHistoryTemp.value?.vendibleId
            && (nonExistVendibleHistoryTemp.value?.sellingPrice === 0 || nonExistVendibleHistoryTemp.value?.sellingPrice)
        },
      )
  })

  const groupPositions = {
    Course: 0,
    Nomination: 1,
    Item: 2,
  }
  const groupNames = computed(() => {
    // NOTE: keyはgroupPositions
    return {
      0: currentClub.value.courseAlias,
      1: currentClub.value.nominationAlias,
      2: 'アイテム',
    }
  })

  const buildVendingHistoriesToSubmit = targetVendingHistoriesTemp => {
    return flatMap(
      targetVendingHistoriesTemp,
      vendingHistoryTemp => {
        // NOTE: size分、valueを作成する
        return map(Array.from(Array(vendingHistoryTemp.size)), () => vendingHistoryTemp.value)
      },
    )
  }
  const vendingHistoriesToSubmit = computed(() => {
    return buildVendingHistoriesToSubmit(vendingHistoriesTemp.value)
  })

  // NOTE: 注文履歴からわかる過去にあげた売上計上先
  const vendingContributorIds = computed(() => {
    const vendingContributions = flatMap(vendingHistories.value, 'attributes.vendingContributions.data')

    return uniq(map(vendingContributions, 'attributes.userId'))
  })

  const buildVendingHistoryTemp = ({
    id = null,
    vendibleId = null,
    vendibleType = null,
    recipientId = null,
    taxCharge = 'normal',
    isNonExistVendible = false,
    vendibleName = null,
    sellingPrice = null,
    vendingContributionsAttributes = [],
    vendibleRecipientsAttributes = [],
    size = 1,
    isArbitraryVendingContribution = false,
    orderedAt = null,
    estimatedTaxPrice = null,
    _destroy = null,
    isPaidInAdvance = false,
  }) => {
    return {
      value: {
        id,
        vendibleId,
        vendibleType,
        recipientId,
        taxCharge,
        isNonExistVendible,
        vendibleName,
        sellingPrice,
        vendingContributionsAttributes,
        vendibleRecipientsAttributes,
        _destroy,
        isPaidInAdvance,
      },
      size,
      isArbitraryVendingContribution,
      orderedAt,
      estimatedTaxPrice,
    }
  }

  const addVendingHistoryTemp = ({
    id = null,
    vendibleId = null,
    vendibleType = null,
    recipientId = null,
    taxCharge = 'normal',
    sellingPrice = null,
    isNonExistVendible = false,
    vendibleName = null,
    vendingContributionsAttributes = [],
    vendibleRecipientsAttributes,
    size = 1,
    isArbitraryVendingContribution = false,
    orderedAt = null,
    _destroy = null,
    isPaidInAdvance = false,
  }) => {
    if (!id && currentClub.value.defaultTaxCharge === 'cut') {
      // NOTE: 新規作成 && デフォルトTAXカットがONの場合は上書きする
      // eslint-disable-next-line no-param-reassign
      taxCharge = 'cut'
    }

    const historyTemp = buildVendingHistoryTemp({
      id,
      vendibleId,
      vendibleType,
      recipientId,
      taxCharge,
      isNonExistVendible,
      vendibleName,
      sellingPrice,
      vendingContributionsAttributes,
      vendibleRecipientsAttributes,
      size,
      isArbitraryVendingContribution,
      orderedAt,
      _destroy,
      isPaidInAdvance,
    })

    vendingHistoriesTemp.value.push(reactive(historyTemp))

    return historyTemp
  }

  const removeVendingHistoryTempByValue = value => {
    const index = findIndex(vendingHistoriesTemp.value, { value })

    if (index < 0) return false

    const removeTargetVendingHistoryTemp = vendingHistoriesTemp.value.splice(index, 1)[0]

    // NOTE: 保存済みのものを削除の場合_destroyをtrueにして元いた場所に返す
    if (removeTargetVendingHistoryTemp.value.id) {
      // eslint-disable-next-line no-underscore-dangle
      removeTargetVendingHistoryTemp.value._destroy = true
      vendingHistoriesTemp.value.splice(index, 0, removeTargetVendingHistoryTemp)
    }

    return removeTargetVendingHistoryTemp
  }

  const removeVendingHistoryTempByIndex = index => {
    const removeTargetVendingHistoryTemp = vendingHistoriesTemp.value.splice(index, 1)[0]

    if (removeTargetVendingHistoryTemp.value.id) {
      // eslint-disable-next-line no-underscore-dangle
      removeTargetVendingHistoryTemp.value._destroy = true
      vendingHistoriesTemp.value.splice(index, 0, removeTargetVendingHistoryTemp)
    }

    return removeTargetVendingHistoryTemp
  }

  const resetVendingHistoriesTemp = () => {
    vendingHistoriesTemp.value = []

    return true
  }

  // NOTE: これからつくる注文履歴からわかる売上計上先
  const pickVendingContributorIds = ({
    targetVendingHistoriesTemp = vendingHistoriesTemp.value,
    targetReferringHistoriesTemp = referringHistoriesTemp.value,
  }) => {
    // NOTE: userIdごとにvendingContributionTriggerもどきを作る
    // {
    //   userId1: [{ triggerableType, triggerableId }],
    //   userId2: [{ triggerableType, triggerableId }],
    // }
    const userAndTriggersFromVendingHistoriesTemp = {}
    each(
      chain(targetVendingHistoriesTemp)
        .filter(o => o.value.vendibleRecipientsAttributes.length > 0)
        .groupBy(o => [o.value.vendibleType, Number(o.value.vendibleId)])
        .mapValues(values => chain(values).flatMap('value.vendibleRecipientsAttributes').map('userId').value())
        .value(),
      (userIds, vendibleTypeAndId) => {
        each(userIds, userId => {
          const [type, id] = vendibleTypeAndId.split(',')
          const pushTarget = { triggerableType: type, triggerableId: Number(id) }

          if (userAndTriggersFromVendingHistoriesTemp[userId]) {
            userAndTriggersFromVendingHistoriesTemp[userId].push(pushTarget)
          } else {
            userAndTriggersFromVendingHistoriesTemp[userId] = [pushTarget]
          }
        })
      },
    )

    const userAndTriggersFromReferringHistoriesTemp = chain(targetReferringHistoriesTemp)
      .groupBy('userId')
      .mapValues(values => map(values, val => { return { triggerableType: 'Referral', triggerableId: Number(val.referralId) } }))
      .value()

    const candidates = mergeWith(
      {},
      userAndTriggersFromVendingHistoriesTemp,
      userAndTriggersFromReferringHistoriesTemp,
      (objValue, srcValue) => {
        if (isArray(objValue)) return objValue.concat(srcValue)

        // 明示的にundefinedを返す（undefinedを返すとデフォルトのマージ動作のため）
        return undefined
      },
    )

    // NOTE: 本当のvendingContributionTriggersと一致するモノをもっているuserIdを返す
    return chain(candidates)
      .pickBy((values, userId) => {
        if (!isValidId(userId)) return false

        const triggers = map(vendingContributionTriggers.value, 'attributes')
        const intersection = intersectionWith(values, triggers, isEqual)

        return intersection.length > 0
      })
      .keys()
      .value()
  }

  // NOTE: 検知された販売貢献人を自動セットする
  //
  // NOTE: 例えば売上計上トリガになっている本指名の注文履歴を削除しても、その削除以前にそのトリガを元に売上計上がついている注文履歴があれば
  // その子も売上計上先としてサジェストする（本指名消したのに、売上計上で出てくる！みたいな場合はそれ）
  const setVendingContributionsAttributes = ({
    targetVendingHistoriesTemp = vendingHistoriesTemp.value,
    targetReferringHistoriesTemp = referringHistoriesTemp.value,
  }) => {
    each(targetVendingHistoriesTemp, vendingHistoryTemp => {
      if (!vendingHistoryTemp.value.vendingContributionsAttributes) return

      // NOTE: 任意でセット/変更したvendingContributionsAttributesであれば無視する
      if (vendingHistoryTemp.isArbitraryVendingContribution) return

      const selfContributorIds = map(vendingHistoryTemp.value.vendingContributionsAttributes, obj => Number(obj.userId))
      const currentContributorIds = uniq(
        map(
          [
            ...vendingContributorIds.value,
            ...pickVendingContributorIds({ targetVendingHistoriesTemp, targetReferringHistoriesTemp }),
          ],
          Number,
        ),
      )
      // NOTE: 同じだったら無視する(無限ループにならないように)
      if (isEqual(selfContributorIds, currentContributorIds)) return

      // NOTE: currentContributorIdsがなければ空をセットして終わり
      if (isEmpty(currentContributorIds)) {
        // eslint-disable-next-line no-param-reassign
        vendingHistoryTemp.value.vendingContributionsAttributes = []

        return
      }

      // NOTE: 複数いる場合は分け前はデフォで等分にするので
      const ratio = 1.0 / currentContributorIds.length

      const newVendingContributionsAttributes = map(currentContributorIds, userId => { return { userId, ratio } })
      // eslint-disable-next-line no-param-reassign
      vendingHistoryTemp.value.vendingContributionsAttributes = newVendingContributionsAttributes
    })
  }

  return {
    vendingHistoriesTemp,
    groupPositions,

    vendingHistories,
    referringHistories,
    referringHistoriesTemp,
    vendingContributionTriggers,
    groupNames,

    itemHistoriesTemp,
    nominationHistoriesTemp,
    courseHistoriesTemp,
    existVendibleHistoriesTemp,
    nonExistVendibleHistoriesTemp,
    isValidToSubmit,
    vendingHistoriesToSubmit,

    vendingContributorIds,

    buildVendingHistoryTemp,
    addVendingHistoryTemp,
    removeVendingHistoryTempByValue,
    removeVendingHistoryTempByIndex,
    resetVendingHistoriesTemp,
    setVendingContributionsAttributes,
    buildVendingHistoriesToSubmit,
    pickVendingContributorIds,
  }
}
