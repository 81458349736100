import {
  chain,
  orderBy,
  groupBy,
  mapValues,
  values,
  flatMap,
  last,
  find,
  map,
} from 'lodash'
import useVendingHistory from './useVendingHistory'

export default () => {
  const { groupPositions, groupNames } = useVendingHistory()

  // TODO
  // NOTE: - アイテムA 花子 数量3 金額1000円 合計金額3000円、みたいなデータを表示するため
  const regulateVendingHistories = vendingHistories => {
    return chain(vendingHistories)
      .orderBy('attributes.createdAt', ['desc'])
      .groupBy(vendingHistory => {
        const {
          vendibleId,
          vendibleName,
          vendibleType,
          sellingPrice,
          taxCharge,
        } = vendingHistory.attributes
        const recipientIds = map(vendingHistory.attributes.vendibleRecipients.data, 'attributes.userId').join(',')

        // NOTE: 任意の金額で注文している可能性もあるためsellingPriceも考慮してグループ分け
        return `${vendibleId || vendibleName}${vendibleType}${sellingPrice}${recipientIds}${taxCharge}`
      })
      .mapValues((vendingHistoriesGrouped, key) => {
        const target = vendingHistoriesGrouped[0]
        const {
          vendibleName,
          sellingPrice,
          vendibleType,
          taxCharge,
        } = target.attributes
        const recipientName = map(target.attributes.vendibleRecipients.data, 'attributes.recipient.data.attributes.name').join(', ') || null
        const quantity = vendingHistoriesGrouped.length
        const groupPosition = vendibleType ? groupPositions[vendibleType] : 100
        const hasVendingContribution = flatMap(vendingHistoriesGrouped, 'attributes.vendingContributions.data').length > 0

        return {
          key,
          vendibleName,
          vendibleType,
          sellingPrice,
          recipientName,
          quantity,
          groupPosition,
          subtotal: (Number(sellingPrice) * Number(quantity)),
          id: target.id,
          actualData: vendingHistoriesGrouped,
          hasVendingContribution,
          taxCharge,
        }
      })
      .values()
      .value()
  }

  const regulatedVendingHistoriesTemp = ({ vendingHistoriesTemp, vendibles, recipients }) => {
    return chain(vendingHistoriesTemp)
      .map(vendingHistoryTemp => {
        const {
          vendibleId,
          vendibleType,
          sellingPrice,
          recipientId,
        } = vendingHistoryTemp.value
        const quantity = Number(vendingHistoryTemp.size)
        const subtotal = Number(sellingPrice) * quantity
        const vendibleName = find(
          vendibles,
          vendible => {
            return Number(vendible.id) === Number(vendibleId)
              && (vendible.attributes.vendibleType) === vendibleType
          },
        )?.attributes?.name
        const recipientName = find(recipients, recipient => Number(recipient.id) === Number(recipientId))?.attributes?.name
        const groupPosition = vendibleType ? groupPositions[vendibleType] : 100

        return {
          vendibleName,
          vendibleType,
          sellingPrice,
          subtotal,
          quantity,
          recipientName,
          groupPosition,
        }
      })
      .value()
  }

  // NOTE: レシートに印字する用（groupごとに分ける）
  const buildPrintItems = regulatedVendingHistories => {
    return chain(regulatedVendingHistories)
      .orderBy('groupPosition', ['asc'])
      .groupBy(o => {
        let groupName = groupNames.value[o.groupPosition]
        // NOTE: レシートに記載する（顧客が見る）ため、isNonExistVendible（vendibleTypeなし）はアイテムにカテゴライズしておく
        if (!groupName) groupName = last(values(groupNames.value))

        return groupName
      })
      .mapValues((items, groupName) => { return { items, groupName } })
      .values()
      .value()
  }

  const regulateReferringHistories = referringHistories => {
    return map(referringHistories, referringHistory => {
      const { referral, user } = referringHistory.attributes

      return {
        name: referral?.data?.attributes?.name,
        user: user?.data?.attributes?.name,
      }
    })
  }

  return {
    // methods
    regulateVendingHistories,
    regulatedVendingHistoriesTemp,
    buildPrintItems,
    regulateReferringHistories,
  }
}
