<!-- eslint-disable vue/max-attributes-per-line -->
<template>
  <v-card
    v-if="!isLoading"
    class="d-flex flex-column justify-space-between"
    elevation="0"
  >
    <v-lazy>
      <v-data-table
        :headers="headers"
        :items="items"
        group-by="groupPosition"
        hide-default-header
        hide-default-footer
        disable-pagination
        no-data-text="データがありません"
      >
        <template #[`group.header`]="{ group }">
          <td :colspan="headers.length">
            {{ groupNames[group] }}
          </td>
        </template>

        <template #[`item.vendibleName`]="{ item }">
          <v-icon
            v-if="item.hasVendingContribution"
            small
            left
            color="primary"
          >
            {{ icons.mdiCashMarker }}
          </v-icon>
          {{ item.vendibleName }}
        </template>

        <template #[`item.recipientName`]="{ item }">
          {{ item.recipientName ? item.recipientName : 'ゲスト' }}
        </template>

        <template #[`item.quantity`]="{ item }">
          {{ `${item.quantity}点` }}
        </template>

        <template #[`item.subtotal`]="{ item }">
          {{ item.sellingPrice ? `¥${item.subtotal.toLocaleString()}` : '¥0' }}
          <sup
            v-if="item.taxCharge === 'included'"
            class="primary--text"
          >
            込み
          </sup>
          <sup
            v-if="item.taxCharge === 'cut'"
            class="primary--text"
          >
            カット
          </sup>
        </template>

        <template #[`item.isPaidInAdvance`]="{ item }">
          <span class="warning--text">
            {{ paidInAdvanceCountText(item.quantity, item.actualData) }}
          </span>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="openedEyeItemKey === item.key && isEditing"
            icon
            :ripple="false"
            @click="closeEye"
          >
            <v-icon
              color="primary"
              small
            >
              {{ icons.mdiEye }}
            </v-icon>
          </v-btn>

          <v-btn
            v-else
            small
            :ripple="false"
            :disabled="isEditing"
            @click="openEye(item)"
          >
            <v-icon small left>
              {{ icons.mdiPencilOutline }}
            </v-icon>
            編集
          </v-btn>

          <v-btn
            small
            :ripple="false"
            color="primary"
            class="ml-1"
            @click="$emit('order', item.actualData)"
          >
            <v-icon small left>
              {{ icons.mdiCartPlus }}
            </v-icon>
            再注文
          </v-btn>
        </template>
      </v-data-table>
    </v-lazy>

    <v-divider />

    <slot name="below-items" />

    <v-spacer></v-spacer>

    <v-app-bar
      v-if="items.length > 0"
      bottom
      height="200"
      elevation="0"
    >
      <div class="w-full">
        <v-card-text
          class="d-flex justify-space-between px-0"
          :class="$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''"
        >
          <print-btn
            subject="会計伝票"
            :print="printAccountingSlip"
          >
            <template #append>
              <v-checkbox
                v-model="withReceiptDetails"
                label="明細を印字"
                dense
                hide-details
                :ripple="false"
                class="mt-4 ms-4"
              />

              <Preview
                :slip-svg="slipSvg"
                class="mt-1"
              />
            </template>
          </print-btn>

          <v-spacer />

          <total-display
            :subtotal="subtotal"
            :tax="serviceFee"
            :total="total"
            :discount-fee="discountFee"
            :paid-in-advance-amount="paidInAdvanceAmount"
            :title="''"
            :subtotal-msg="'小計'"
            :tax-msg="'税サ等'"
            :total-msg="'合計'"
            class="mb-4 px-2"
          />
        </v-card-text>
      </div>
    </v-app-bar>
  </v-card>

  <v-skeleton-loader
    v-else
    type="table"
  />
</template>

<script>
import {
  ref,
  computed,
  inject,
  getCurrentInstance,
  onMounted,
} from '@vue/composition-api'
import {
  mdiEye,
  mdiPencilOutline,
  mdiCashMarker,
  mdiCartPlus,
} from '@mdi/js'
import { useAccountingSlipPrint } from '@yonarezi/yonarezi3.slip-print'
import { removePortFromIpAddress } from '@/utils/removePortFromIpAddress'
import useCurrentData from '@/views/composable/useCurrentData'
import useDateFormat from '@/views/composable/useDateFormat'
import useVendingHistory from '@/views/composable/useVendingHistory'
import useReceiptPrinterDisplay from '@/views/composable/useReceiptPrinterDisplay'
import TotalDisplay from '@/views/components/util/TotalDisplay.vue'
import PrintBtn from '@/views/components/util/PrintBtn.vue'
import Preview from '@/views/components/printer/Preview.vue'

export default {
  components: {
    TotalDisplay,
    PrintBtn,
    Preview,
  },
  props: {
    subtotal: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    serviceFee: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    // NOTE: 合計金額に対して店舗独自の丸目処理を行うため単純に（小計 + 税サ）が合計金額とならない
    total: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    discountFee: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    paidInAdvanceAmount: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    customerCount: {
      type: [Number, String],
      required: true,
    },
    tableId: {
      type: [Number, String],
      validator: value => !Number.isNaN(Number(value)),
      required: true,
    },
    tableName: {
      type: String,
      required: true,
      default: '',
    },
    startAt: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const vendingHistories = inject('vendingHistories', [])
    const isReceiptPrinterConnected = inject('isReceiptPrinterConnected', false)
    const printer = inject('printer', null)

    const { currentClub } = useCurrentData()
    const { hhmm } = useDateFormat()
    const { groupNames } = useVendingHistory()
    const { regulateVendingHistories } = useReceiptPrinterDisplay()

    const openedEyeItemKey = ref()
    const isLoading = ref(true)
    const withReceiptDetails = ref(true)

    const headers = [
      { text: 'vendibleName', value: 'vendibleName' },
      { text: 'recipientName', value: 'recipientName' },
      { text: 'quantity', value: 'quantity' },
      { text: 'subtotal', value: 'subtotal' },
      { text: 'isPaidInAdvance', value: 'isPaidInAdvance' },
      {
        text: 'actions',
        value: 'actions',
        align: 'end',
        sortable: false,
      },
    ]

    const vm = getCurrentInstance().proxy

    // NOTE: - アイテムA 花子 数量3 金額1000円 合計金額3000円、みたいなデータを表示するため
    const items = computed(() => {
      return regulateVendingHistories(vendingHistories.value)
    })

    const closeEye = () => {
      openedEyeItemKey.value = ''
      emit('eye:closed')
    }

    const openEye = item => {
      closeEye()
      openedEyeItemKey.value = item.key
      emit('eye:opened', item.actualData)
    }

    // NOTE: YONAREZI 3
    const accountingSlipOptions = computed(() => {
      return {
        ipAddress: removePortFromIpAddress(currentClub.value.printIpAddress),
        clubName: currentClub.value.name,
        invoiceNumber: currentClub.value.invoiceNumber,
        address: currentClub.value.address,
        tel: currentClub.value.telephoneNumber,
        customerCount: props.customerCount,
        tableCode: props.tableName,
        startAt: hhmm(props.startAt),
        subtotal: props.subtotal,
        tax: props.serviceFee, // serviceFeeって名前だけど税サ
        total: props.total,
        paidInAdvanceAmount: props.paidInAdvanceAmount,
        withOrderDetails: withReceiptDetails.value,
        vendingHistories: vendingHistories.value,
        printer: printer.value,
      }
    })
    const { send: printAccountingSlip, onReceive, svg: slipSvg } = useAccountingSlipPrint(accountingSlipOptions)
    onReceive.value = response => {
      if (!response.success) {
        vm.$toast.error(response)
      }
    }

    const paidInAdvanceCountText = (quantity, actualData) => {
      const count = actualData.filter(item => item.attributes.isPaidInAdvance).length

      if (count === 0) return ''

      if (count === quantity) {
        return '前金'
      }

      return `${count}/${quantity} 前金`
    }

    onMounted(() => { isLoading.value = false })

    return {
      // data
      headers,
      openedEyeItemKey,
      isLoading,
      items,
      groupNames,
      vendingHistories,
      isReceiptPrinterConnected,
      withReceiptDetails,

      // computed
      slipSvg,

      // methods
      openEye,
      closeEye,
      printAccountingSlip,
      paidInAdvanceCountText,

      icons: {
        mdiEye,
        mdiPencilOutline,
        mdiCashMarker,
        mdiCartPlus,
      },
    }
  },
}
</script>
