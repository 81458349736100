var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center pt-2"},[_c('v-btn',{staticClass:"vertical-btn-text",attrs:{"icon":"","ripple":false,"color":_vm.vendingContributionsAttributes.length > 0 ? 'primary' : ''},on:{"click":function($event){return _vm.switchIsManual(true)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCashMarker)+" ")]),_c('span',{staticClass:"text-xs"},[_vm._v("売上")])],1),(!_vm.isManual)?_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.vendingContributionsAttributes),function(vendingContributionsAttribute,vendingContributionsAttributeIdx){return _c('v-chip',{key:("vendingContributionsAttributes[" + vendingContributionsAttributeIdx + "]"),attrs:{"pill":"","small":""},on:{"click":function($event){return _vm.switchIsManual(true)}}},[_vm._v(" "+_vm._s(_vm.pickUser(vendingContributionsAttribute.userId).attributes.name)+" ")])}),1):_c('v-select',{staticClass:"pa-0 ma-0",attrs:{"value":_vm.vendingContributionsAttributes,"items":_vm.userItems,"item-value":function (item) {
      if (item.type === 'user') { return item.id }
      return item.userId
    },"value-comparator":function (a, b) {
      if (!(+a > 0 && +b > 0)) { return false }
      return +a === +b
    },"item-text":"attributes.name","chips":"","multiple":"","disable-lookup":"","hide-details":"","single-line":"","small-chips":"","hide-selected":"","append-outer-icon":_vm.icons.mdiPencilOffOutline,"no-data-text":"選択可能なデータがありません","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){return _vm.addVendingContributions($event)},"input":function (){},"click:append-outer":_vm.resetVendingContributions},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var user = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(!(_vm.pickVendingContribution(user.id)._destroy))?_c('v-chip',_vm._g(_vm._b({attrs:{"close":"","small":""},on:{"click:close":function($event){return _vm.removeVendingContribution(user)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(user.attributes.name)+" ( "),_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"36px"}},[_vm._v(" "+_vm._s(Math.round(_vm.pickVendingContribution(user.id).ratio * 100))+" ")]),_vm._v(" %) ")]):_vm._e()]}}],null,true),model:{value:(_vm.onUserRatios[user.id]),callback:function ($$v) {_vm.$set(_vm.onUserRatios, user.id, $$v)},expression:"onUserRatios[user.id]"}},[_c('v-list',[_c('v-list-item',[_c('v-text-field',{staticClass:"py-0",attrs:{"type":"number","inputmode":"numeric","pattern":"[0-9]*","value":Math.round(_vm.pickVendingContribution(user.id).ratio * 100),"min":"0","suffix":"%","hide-details":""},on:{"input":function($event){return _vm.changeVendingContributionRatio($event, user)}}}),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","x-small":"","ripple":false},on:{"click":function($event){_vm.onUserRatios[user.id] = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCheck))])],1)],1)],1)],1)],1)]}},{key:"item",fn:function(ref){
    var userItem = ref.item;
return [(!userItem.attributes)?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(userItem.header)}})]:[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"hide-details":"","disabled":"","ripple":false}})],1),_c('v-list-item-avatar',{staticClass:"mr-2",attrs:{"size":"32"}},[(userItem.attributes.profileImage)?_c('v-img',{attrs:{"src":userItem.attributes.profileImage}}):_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountCircleOutline)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(userItem.attributes.name)+" ")])],1)]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }