import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getReceipts({
    date = null,
    interval = null,
    id = null,
    endDate = null,
  }) {
    return axiosIns.get(
      '/waiter/receipts',
      {
        params: snakecaseKeys({
          date,
          interval,
          id,
          endDate,
        }),
      },
    )
  },
  getReceipt(id) {
    return axiosIns.get(`/waiter/receipts/${id}`)
  },
  createReceipt({ tableId, receiptAttributes }) {
    const formData = serialize(snakecaseKeys({
      table: { receiptAttributes },
    }))

    const { images } = receiptAttributes
    formData.append('table[receipt_attributes][images]', images)

    return axiosIns.post(`/waiter/tables/${tableId}/receipts/`, formData)
  },
  updateReceipt({ id, receiptAttributes }) {
    const formData = serialize(snakecaseKeys({
      table: { receiptAttributes },
    }))
    const { images } = receiptAttributes
    formData.append('table[receipt_attributes][images]', images)

    return axiosIns.put(`/waiter/receipts/${id}`, formData)
  },
  deleteReceipt(id) {
    return axiosIns.delete(`/waiter/receipts/${id}`)
  },
}
