<template>
  <div class="w-full d-flex justify-space-between align-center">
    <div class="w-full d-flex justify-center align-center">
      <div @click="confirm">
        <v-checkbox
          v-if="!alreadyConfirmed"
          :value="isConfirmed"
          hide-details
          :on-icon="icons.mdiLockOpenVariant"
          :off-icon="icons.mdiLock"
          :disabled="!isValid"
          :ripple="false"
          class="ma-0 pa-0"
        />
      </div>
      <v-btn
        color="primary"
        block
        :loading="isSubmitting || multipleClickGuard"
        :disabled="multipleClickGuard || isSubmitting || !isValid || !isConfirmed"
        :ripple="false"
        @click="click"
      >
        {{ btnMessage }}
      </v-btn>
    </div>
    <v-spacer
      v-if="cancelable"
      class="mx-2"
    />
    <v-btn
      v-if="cancelable"
      color="secondary"
      text
      small
      :ripple="false"
      class="ml-4"
      @click="$emit('cancel')"
    >
      キャンセル
    </v-btn>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from '@vue/composition-api'
import { mdiLockOpenVariant, mdiLock } from '@mdi/js'

export default {
  props: {
    isConfirmed: {
      type: Boolean,
      default: false,
      required: true,
    },
    isValid: {
      type: Boolean,
      default: false,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    btnMessage: {
      type: String,
      default: '送信',
    },
    cancelable: {
      type: Boolean,
      default: false,
    },
    alreadyConfirmed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy
    const multipleClickGuard = ref(false)

    const confirm = () => {
      if (!props.isValid) {
        vm.$toast.error('未入力の項目があります')

        return false
      }

      emit('confirmed', !props.isConfirmed)

      return true
    }

    const click = () => {
      // NOTE: isSubmittingが降りてくるまで時間がかかってloadingやdisabledが反応しない（連打される、ということが発生したため）
      multipleClickGuard.value = true

      const revertMultipleClickGuard = () => {
        multipleClickGuard.value = false
      }
      setTimeout(revertMultipleClickGuard, 1500)

      emit('clicked')
    }

    return {
      // data
      multipleClickGuard,

      // methods
      confirm,
      click,
      icons: {
        mdiLockOpenVariant,
        mdiLock,
      },
    }
  },
}
</script>
