var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('v-card',{staticClass:"d-flex flex-column justify-space-between",attrs:{"elevation":"0"}},[_c('v-lazy',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"group-by":"groupPosition","hide-default-header":"","hide-default-footer":"","disable-pagination":"","no-data-text":"データがありません"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
return [_c('td',{attrs:{"colspan":_vm.headers.length}},[_vm._v(" "+_vm._s(_vm.groupNames[group])+" ")])]}},{key:"item.vendibleName",fn:function(ref){
var item = ref.item;
return [(item.hasVendingContribution)?_c('v-icon',{attrs:{"small":"","left":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCashMarker)+" ")]):_vm._e(),_vm._v(" "+_vm._s(item.vendibleName)+" ")]}},{key:"item.recipientName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.recipientName ? item.recipientName : 'ゲスト')+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.quantity) + "点"))+" ")]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sellingPrice ? ("¥" + (item.subtotal.toLocaleString())) : '¥0')+" "),(item.taxCharge === 'included')?_c('sup',{staticClass:"primary--text"},[_vm._v(" 込み ")]):_vm._e(),(item.taxCharge === 'cut')?_c('sup',{staticClass:"primary--text"},[_vm._v(" カット ")]):_vm._e()]}},{key:"item.isPaidInAdvance",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"warning--text"},[_vm._v(" "+_vm._s(_vm.paidInAdvanceCountText(item.quantity, item.actualData))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.openedEyeItemKey === item.key && _vm.isEditing)?_c('v-btn',{attrs:{"icon":"","ripple":false},on:{"click":_vm.closeEye}},[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1):_c('v-btn',{attrs:{"small":"","ripple":false,"disabled":_vm.isEditing},on:{"click":function($event){return _vm.openEye(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_vm._v(" 編集 ")],1),_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","ripple":false,"color":"primary"},on:{"click":function($event){return _vm.$emit('order', item.actualData)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCartPlus)+" ")]),_vm._v(" 再注文 ")],1)]}}],null,true)})],1),_c('v-divider'),_vm._t("below-items"),_c('v-spacer'),(_vm.items.length > 0)?_c('v-app-bar',{attrs:{"bottom":"","height":"200","elevation":"0"}},[_c('div',{staticClass:"w-full"},[_c('v-card-text',{staticClass:"d-flex justify-space-between px-0",class:_vm.$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : ''},[_c('print-btn',{attrs:{"subject":"会計伝票","print":_vm.printAccountingSlip},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-checkbox',{staticClass:"mt-4 ms-4",attrs:{"label":"明細を印字","dense":"","hide-details":"","ripple":false},model:{value:(_vm.withReceiptDetails),callback:function ($$v) {_vm.withReceiptDetails=$$v},expression:"withReceiptDetails"}}),_c('Preview',{staticClass:"mt-1",attrs:{"slip-svg":_vm.slipSvg}})]},proxy:true}],null,false,2771574280)}),_c('v-spacer'),_c('total-display',{staticClass:"mb-4 px-2",attrs:{"subtotal":_vm.subtotal,"tax":_vm.serviceFee,"total":_vm.total,"discount-fee":_vm.discountFee,"paid-in-advance-amount":_vm.paidInAdvanceAmount,"title":'',"subtotal-msg":'小計',"tax-msg":'税サ等',"total-msg":'合計'}})],1)],1)]):_vm._e()],2):_c('v-skeleton-loader',{attrs:{"type":"table"}})}
var staticRenderFns = []

export { render, staticRenderFns }