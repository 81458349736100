import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getVendingHistories({
    date = null,
    interval = null,
    checkedOut = null,
    beforeCheckOut = null,
    vendibleId = null,
    vendibleType = null,
    recipientId = null,
    tableId = null,
    isNonExistVendible = null,
    contributorId = null,
    isToday = false,
    endDate = null,
  }) {
    return axiosIns.get(
      '/waiter/vending_histories',
      {
        params: snakecaseKeys({
          date,
          interval,
          checkedOut,
          beforeCheckOut,
          vendibleId,
          vendibleType,
          recipientId,
          tableId,
          isNonExistVendible,
          contributorId,
          isToday,
          endDate,
        }),
      },
    )
  },
  createVendingHistories({ tableId, vendingHistories }) {
    const formData = serialize(snakecaseKeys({
      table: {
        vendingHistoriesAttributes: vendingHistories,
      },
    }))

    return axiosIns.post(`waiter/tables/${tableId}/vending_histories`, formData)
  },
  updateVendingHistories({ tableId, vendingHistories }) {
    const formData = serialize(snakecaseKeys({
      table: {
        vendingHistoriesAttributes: vendingHistories,
      },
    }))

    return axiosIns.put(`waiter/tables/${tableId}/vending_histories`, formData)
  },
  updateAllVendingHistories({ ids, attributes }) {
    const formData = serialize(snakecaseKeys({
      vendingHistories: { ids, ...attributes },
    }))

    return axiosIns.put('waiter/vending_histories/update_all', formData)
  },
}
