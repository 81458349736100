<template>
  <div class="d-flex align-center pt-2">
    <v-btn
      icon
      class="vertical-btn-text"
      :ripple="false"
      :color="vendingContributionsAttributes.length > 0 ? 'primary' : ''"
      @click="switchIsManual(true)"
    >
      <v-icon>
        {{ icons.mdiCashMarker }}
      </v-icon>

      <span class="text-xs">売上</span>
    </v-btn>

    <v-chip-group
      v-if="!isManual"
      column
    >
      <v-chip
        v-for="(vendingContributionsAttribute, vendingContributionsAttributeIdx) in vendingContributionsAttributes"
        :key="`vendingContributionsAttributes[${vendingContributionsAttributeIdx}]`"
        pill
        small
        @click="switchIsManual(true)"
      >
        {{ pickUser(vendingContributionsAttribute.userId).attributes.name }}
      </v-chip>
    </v-chip-group>

    <v-select
      v-else
      :value="vendingContributionsAttributes"
      :items="userItems"
      :item-value="item => {
        if (item.type === 'user') return item.id
        return item.userId
      }"
      :value-comparator="(a, b) => {
        if (!(+a > 0 && +b > 0)) return false
        return +a === +b
      }"
      item-text="attributes.name"
      chips
      multiple
      disable-lookup
      hide-details
      single-line
      small-chips
      hide-selected
      :append-outer-icon="icons.mdiPencilOffOutline"
      no-data-text="選択可能なデータがありません"
      class="pa-0 ma-0"
      :menu-props="{ auto: true, offsetY: true }"
      @change="addVendingContributions($event)"
      @input="()=>{}"
      @click:append-outer="resetVendingContributions"
    >
      <template #selection="{ item: user }">
        <v-menu
          v-model="onUserRatios[user.id]"
          :close-on-content-click="false"
          transition="scale-transition"
          bottom
          offset-y
        >
          <template #activator="{ on, attrs }">
            <v-chip
              v-if="!(pickVendingContribution(user.id)._destroy)"
              close
              small
              v-bind="attrs"
              v-on.prevent="on"
              @click:close="removeVendingContribution(user)"
            >
              {{ user.attributes.name }}
              (
              <span
                class="text-truncate"
                style="max-width: 36px"
              >
                {{ Math.round(pickVendingContribution(user.id).ratio * 100) }}
              </span>
              %)
            </v-chip>
          </template>

          <v-list>
            <v-list-item>
              <v-text-field
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                :value="Math.round(pickVendingContribution(user.id).ratio * 100)"
                min="0"
                suffix="%"
                hide-details
                class="py-0"
                @input="changeVendingContributionRatio($event, user)"
              />

              <v-list-item-action>
                <v-btn
                  icon
                  x-small
                  :ripple="false"
                  @click="onUserRatios[user.id] = false"
                >
                  <v-icon>{{ icons.mdiCheck }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template #item="{ item: userItem }">
        <template v-if="!userItem.attributes">
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <v-list-item-content v-text="userItem.header" />
        </template>

        <template v-else>
          <v-list-item-action>
            <v-checkbox
              hide-details
              disabled
              :ripple="false"
            />
          </v-list-item-action>

          <v-list-item-avatar
            size="32"
            class="mr-2"
          >
            <v-img
              v-if="userItem.attributes.profileImage"
              :src="userItem.attributes.profileImage"
            />
            <v-icon v-else>
              {{ icons.mdiAccountCircleOutline }}
            </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              {{ userItem.attributes.name }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-select>
  </div>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'
import { useVModel } from '@vueuse/core'
import {
  chain,
  each,
  groupBy,
  find,
  keys,
  findIndex,
  update,
  map,
  xor,
  isEmpty,
  cloneDeep,
} from 'lodash'
import {
  mdiCashMarker,
  mdiPencilOffOutline,
  mdiCheck,
  mdiAccountCircleOutline,
} from '@mdi/js'

export default {
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    users: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const vendingContributionsAttributes = useVModel(props, 'value')
    // NOTE: 基本的にvendingContributionsAttributes(売上計上先)は、useVendingHistory>setVendingContributionsAttributesで自動セットされる。
    const isManual = ref(false)
    const originalVendingContributionsAttributes = cloneDeep(props.value)
    const onUserRatios = reactive(chain(props.users).keyBy('id').mapValues(() => false).value())

    const userItems = computed(() => {
      const roleMsg = {
        cast: 'キャスト',
        waiter: 'ボーイ',
        alliance: 'アライアンス',
      }
      const lists = []
      const usersGrouped = groupBy(props.users, 'attributes.role')

      each(keys(roleMsg), role => {
        if (!usersGrouped[role]) return

        lists.push({ header: roleMsg[role] })
        lists.push(...usersGrouped[role])
      })

      return lists
    })

    const pickUser = id => {
      return find(props.users, user => +user.id === +id) || { attributes: { name: '' } }
    }

    const pickVendingContribution = userId => {
      return find(vendingContributionsAttributes.value, o => +o.userId === +userId) || { ratio: 0 }
    }

    const switchIsManual = bool => {
      isManual.value = bool
      emit('is-manual', isManual.value)
    }

    const addVendingContributions = incomingVendingContributorIds => {
      // NOTE: incomingVendingContributorIdsは選択済みのidも含まれているので排他的論理和つかう
      const newVendingContributorIds = xor(
        map(vendingContributionsAttributes.value, o => +o.userId),
        map(incomingVendingContributorIds, Number),
      )

      // NOTE: 割合は新しい人数で等分してリセットする(_destroyを除く)
      const persistentVendingContributorIds = chain(vendingContributionsAttributes.value)
        .reject('_destroy')
        .map(o => +o.userId)
        .value()
      const ratio = 1 / [...persistentVendingContributorIds, ...newVendingContributorIds].length

      each(newVendingContributorIds, id => {
        vendingContributionsAttributes.value.push({ userId: id, ratio })
      })

      // NOTE: 保存済みのvendingContributionAttributesに対してもratioを変更しないといけないので
      each(vendingContributionsAttributes.value, obj => {
        update(obj, 'ratio', () => ratio)
      })

      switchIsManual(true)
    }

    const removeVendingContribution = user => {
      const index = findIndex(vendingContributionsAttributes.value, o => +o.userId === +user.id)
      const deleteTargetVendingContribution = vendingContributionsAttributes.value.splice(index, 1)[0]
      const persistentVendingContributorSize = chain(vendingContributionsAttributes.value).reject('_destroy').value().length

      let ratio = 0
      // NOTE: 割合は残った人数で等分してリセットする
      if (!isEmpty(vendingContributionsAttributes.value)) ratio = 1 / persistentVendingContributorSize

      each(vendingContributionsAttributes.value, obj => {
        update(obj, 'ratio', () => ratio)
      })

      // NOTE: 保存済みのもの対して削除であれば、_destroy:trueを付与して戻す
      if (deleteTargetVendingContribution?.id) {
        vendingContributionsAttributes.value.push({ ...deleteTargetVendingContribution, _destroy: true })
      }

      switchIsManual(true)
    }

    const resetVendingContributions = () => {
      vendingContributionsAttributes.value = cloneDeep(originalVendingContributionsAttributes)

      switchIsManual(false)
    }

    const changeVendingContributionRatio = (percent, user) => {
      const newRatio = Number(percent) / 100
      const index = findIndex(
        vendingContributionsAttributes.value,
        o => +o.userId === +user.id,
      )
      update(vendingContributionsAttributes.value[index], 'ratio', () => newRatio)

      switchIsManual(true)
    }

    return {
      // data
      isManual,
      vendingContributionsAttributes,
      originalVendingContributionsAttributes,
      onUserRatios,

      // computed
      userItems,

      // methods
      pickUser,
      pickVendingContribution,
      switchIsManual,
      addVendingContributions,
      removeVendingContribution,
      resetVendingContributions,
      changeVendingContributionRatio,

      icons: {
        mdiCashMarker,
        mdiPencilOffOutline,
        mdiCheck,
        mdiAccountCircleOutline,
      },
    }
  },
}
</script>
