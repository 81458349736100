var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{attrs:{"value":_vm.vendibleRecipientsAttributes,"items":_vm.userItems,"item-value":function (item) {
      if (item.type === 'user') { return item.id }
      return item.userId
    },"value-comparator":function (a, b) {
      if (!(+a > 0 && +b > 0)) { return false }
      return +a === +b
    },"item-text":"attributes.name","error":_vm.error,"placeholder":"ゲスト","chips":"","disable-lookup":"","hide-details":"","multiple":"","single-line":"","small-chips":"","hide-selected":"","no-data-text":"選択可能なデータがありません","menu-props":{ auto: true, offsetY: true }},on:{"change":function($event){return _vm.addVendibleRecipients($event)},"input":function (){}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var user = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-badge',{attrs:{"dot":"","left":"","overlap":"","avatar":"","offset-x":"12","offset-y":"12","color":_vm.badgeColor(user)}},[(!(_vm.pickVendibleRecipient(user.id)._destroy))?_c('v-chip',_vm._g({attrs:{"close":"","pill":""},on:{"click:close":function($event){return _vm.removeVendibleRecipient(user)}}},on),[_c('v-avatar',{attrs:{"left":""}},[(user.attributes.profileImage)?_c('v-img',{attrs:{"src":user.attributes.profileImage}}):_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountCircleOutline)+" ")])],1),_vm._v(" "+_vm._s(user.attributes.name)+" "),(_vm.vendibleRecipientsAttributes.length > 1 || _vm.vendibleRecipientsAttributes.some(function (o) { return +o.ratio !== 1.0; }))?[_vm._v(" ( "),_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"48px"}},[_vm._v(" "+_vm._s(Math.round(_vm.pickVendibleRecipient(user.id).ratio * 100))+" ")]),_vm._v(" %) ")]:_vm._e()],2):_vm._e()],1)]}}],null,true),model:{value:(_vm.onUserRatios[user.id]),callback:function ($$v) {_vm.$set(_vm.onUserRatios, user.id, $$v)},expression:"onUserRatios[user.id]"}},[_c('v-list',[_c('v-list-item',[_c('v-text-field',{staticClass:"py-0",attrs:{"type":"number","inputmode":"numeric","pattern":"[0-9]*","value":Math.round(_vm.pickVendibleRecipient(user.id).ratio * 100),"min":"0","suffix":"%","hide-details":""},on:{"input":function($event){return _vm.changeVendibleRecipientRatio($event, user)}}}),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","x-small":"","ripple":false},on:{"click":function($event){_vm.onUserRatios[user.id] = false}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCheck))])],1)],1)],1)],1)],1)]}},{key:"item",fn:function(ref){
    var userItem = ref.item;
    var attrs = ref.attrs;
return [(!userItem.attributes)?[_c('v-list-item-content',{domProps:{"textContent":_vm._s(userItem.header)}})]:[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"hide-details":"","ripple":false},model:{value:(attrs.inputValue),callback:function ($$v) {_vm.$set(attrs, "inputValue", $$v)},expression:"attrs.inputValue"}})],1),_c('v-badge',{attrs:{"dot":"","left":"","overlap":"","avatar":"","offset-x":"24","offset-y":"16","color":_vm.badgeColor(userItem)}},[_c('v-list-item-avatar',{staticClass:"mr-2",attrs:{"size":"32"}},[(userItem.attributes.profileImage)?_c('v-img',{attrs:{"src":userItem.attributes.profileImage}}):_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountCircleOutline)+" ")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(userItem.attributes.name)+" ")])],1)]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }