<template>
  <v-card-text class="d-flex justify-space-between ma-0 pa-0">
    <div class="d-flex flex-column justify-space-between">
      <span class="font-weight-semibold">
        {{ title }}
      </span>
      <slot name="below-title" />
    </div>
    <v-spacer />
    <div
      class="px-0 d-flex flex-column"
    >
      <table>
        <tr>
          <td class="pr-16">
            {{ subtotalMsg }}
          </td>
          <td class="text-right">
            {{ `¥${subtotal.toLocaleString()}` }}
          </td>
        </tr>
        <tr>
          <td class="pr-16">
            {{ taxMsg }}
          </td>
          <td class="text-right">
            {{ `¥${tax.toLocaleString()}` }}
          </td>
        </tr>
        <tr
          v-if="discountFee > 0"
        >
          <td class="pr-16">
            {{ discountFeeMsg }}
          </td>
          <td class="text-right">
            {{ `¥${discountFee.toLocaleString()}` }}
          </td>
        </tr>
        <tr
          v-if="processingFee > 0"
        >
          <td class="pr-16">
            {{ processingFeeMsg }}
          </td>
          <td class="text-right">
            {{ `¥${processingFee.toLocaleString()}` }}
          </td>
        </tr>
      </table>

      <v-divider class="my-1" />

      <table>
        <tr>
          <td class="pr-16 font-weight-semibold">
            {{ totalMsg }}
          </td>
          <th class="text-right font-weight-semibold">
            {{ `¥${(total).toLocaleString()}` }}
          </th>
        </tr>
      </table>

      <template v-if="total && paidInAdvanceAmount && total > 0 && paidInAdvanceAmount > 0">
        <v-divider class="my-1 mt-4" />
        <table>
          <tr>
            <td class="pr-16">
              前金
            </td>
            <td class="text-right">
              {{ `¥${paidInAdvanceAmount.toLocaleString()}` }}
            </td>
          </tr>
          <tr>
            <td class="pr-16">
              未領収金
            </td>
            <td class="text-right">
              {{ `¥${(total - paidInAdvanceAmount).toLocaleString()}` }}
            </td>
          </tr>
        </table>
      </template>
    </div>
  </v-card-text>
</template>

<script>
export default {
  props: {
    subtotal: {
      type: [Number, String],
      default: 0,
    },
    tax: {
      type: [Number, String],
      default: 0,
    },
    processingFee: {
      type: [Number, String],
      default: 0,
    },
    discountFee: {
      type: [Number, String],
      default: 0,
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    paidInAdvanceAmount: {
      type: [Number, String],
      default: 0,
    },
    title: {
      type: String,
      default: '内訳',
    },
    subtotalMsg: {
      type: String,
      default: '小計',
    },
    discountFeeMsg: {
      type: String,
      default: '値引',
    },
    taxMsg: {
      type: String,
      default: '税サ等',
    },
    processingFeeMsg: {
      type: String,
      default: '会計手数料',
    },
    totalMsg: {
      type: String,
      default: '合計',
    },
  },
}
</script>
