<template>
  <div>
    <v-dialog
      :value="hasQrCode"
      transition="dialog-bottom-transition"
      persistent
      fullscreen
      scrollable
      content-class="receipt-qr-code-share"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          :ripple="false"
          v-bind="attrs"
          v-on="on"
          @click="displayQrCode"
        >
          <v-icon left>
            {{ icons.mdiQrcode }}
          </v-icon>
          電子レシートを共有
        </v-btn>
      </template>

      <v-card
        tile
        style="background-size: cover; background-position: center;"
        :style="{ backgroundImage: `url(${currentClub.receiptQrCodeBackgroundImage})` }"
      >
        <v-app-bar
          flat
          style="background: transparent"
        >
          <v-spacer />
          <v-app-bar-nav-icon>
            <v-btn
              fab
              icon
              large
              :ripple="false"
              @click="deleteQrCode"
            >
              <v-icon>
                {{ icons.mdiWindowClose }}
              </v-icon>
            </v-btn>
          </v-app-bar-nav-icon>
        </v-app-bar>

        <v-card-text class="d-flex flex-column align-center">
          <div class="blurred-box d-flex flex-column align-center">
            <canvas
              v-if="hasQrCode && urls.length > 0"
              id="qrcode"
              style="margin: 0 auto;"
            />

            <countdown
              :key="`${urls.at(-1)}-${hasQrCode}`"
              :end-time="new Date().getTime() + (60000 * 5)"
              class="mt-4"
            >
              <template #process="{timeObj}">
                <h2 style="color: #fff !important">
                  {{ `${timeObj.m}:${timeObj.s}` }}
                </h2>
              </template>

              <template #finish>
                <h2 style="color: #fff !important">
                  期限切れ
                </h2>
              </template>
            </countdown>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import QrCode from 'qrcode'
import { ref, onMounted, nextTick } from '@vue/composition-api'
import { mdiQrcode, mdiWindowClose } from '@mdi/js'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import Vue from 'vue'

Vue.use(vueAwesomeCountdown)

export default {
  props: {
    urls: {
      type: Array,
      default: () => [],
    },
    immediateShare: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const hasQrCode = ref(false)

    const deleteQrCode = () => {
      const canvas = document.getElementById('qrcode')
      // 一度全部削除
      if (canvas) {
        const ctx = canvas.getContext('2d')
        ctx.clearRect(0, 0, canvas.width, canvas.height)
      }

      hasQrCode.value = false

      emit('closed')
    }

    const displayQrCode = async () => {
      hasQrCode.value = true
      await nextTick()

      const canvas = document.getElementById('qrcode')

      const url = props.urls.at(-1)
      if (!url) return

      QrCode.toCanvas(canvas, url, error => {
        if (error) console.error(error)
      })
    }

    onMounted(() => {
      if (props.immediateShare && props.urls.length > 0) displayQrCode()
    })

    return {
      // data
      hasQrCode,

      //  methods
      displayQrCode,
      deleteQrCode,

      icons: {
        mdiQrcode,
        mdiWindowClose,
      },
    }
  },
}
</script>

<style lang="scss">
.receipt-qr-code-share .blurred-box {
  backdrop-filter: blur(10px);
  box-shadow: inset 0 0 0 200px rgb(0 0 0 / 5%);
  padding: 32px;
}

.receipt-qr-code-share #qrcode {
  box-shadow: 2.8px 2.8px 2.2px rgb(0 0 0 / 2%),
              6.7px 6.7px 5.3px rgb(0 0 0 / 3%),
              12.5px 12.5px 10px rgb(0 0 0 / 4%),
              22.3px 22.3px 17.9px rgb(0 0 0 / 4%),
              41.8px 41.8px 33.4px rgb(0 0 0 / 5%),
              100px 100px 80px rgb(0 0 0 / 7%);
}
</style>
